<template>
    <div>
       <Report/>
    </div>
</template>

<script>
import Report from './components/Report.vue'
export default {
  name: 'App',
  components: {
    Report
  }
}
</script>

<style>

</style>
