<template>
  <div id="app" >
    <div class="report-container" v-if="!isVRGame">
      <div class="title-content" v-if="showApp">
        <div class="title"><span>下肢康复训练报告</span></div>
        <div class="side-title"><span>康复机器人iReGo综合训练报告</span></div>
      </div>
      <div class="main-content">
        <!-- 基本信息 -->
        <div class="basic-info" v-if="showApp">
          <div class="content-title">
            <span>基本信息</span>
            <div></div>
          </div>
          <table class="info-table">
            <tr>
              <td>姓名：{{ list.name }}</td>
              <td>身高：{{ list.height }} cm</td>
              <!-- <td>手术时间：{{detils.operation}}</td> -->
              <td>检查日期：{{ reporttime }}</td>
              <!-- <td>{{list.regdate}}</td> -->
            </tr>
            <tr>
              <td>账号：{{ list.phone }}</td>
              <td>体重：{{ list.weight }} kg</td>
              <td>科室：康复科</td>
            </tr>
            <tr>
              <td>性别：{{ list.sex !== null ? (list.sex === 1 ? '男':'女') : '' }}</td>
              <!-- <td>性别：{{ details.sex === 1 ? '男':'女' }}</td> -->
              <td>
                <!-- 病史：{{details.medicalHistory}} -->
                偏瘫侧：{{
                  this.list.irego !== undefined ? (JSON.parse(this.list.irego).hemi === 1 ? "左侧" :JSON.parse(this.list.irego).hemi === 2 ? "右侧" : JSON.parse(this.list.irego).hemi === 3 ? "双侧":'无'): "无"
                }}
              </td>
               <!-- <td>年龄：{{ details.age }}</td> -->
               <td>年龄：{{ age || list.age }}</td>
            </tr>
            <!-- <tr>
              <td>年龄：{{ age || list.age }}</td>
            </tr> -->
          </table>
        </div>
        <!-- 行走 -->
        <div v-for="(item, index) in ReportProcess" :key="index" class="main">
          <!-- 行走 -->
          <div class="walk-info" v-if="item === '1'">
            <!-- <div>{{toolNumber(2.08e+33)}}</div> -->
            <div class="content-title">
              <span>行走训练</span>
              <div></div>
            </div>
            <div class="tips-info">
              <img src="@/assets/report/form.png" alt="" />
            </div>
            <div class="walk—table">
              <!-- <img src='../assets/image/行走训练列表.png' alt=''> -->
              <table class="table-content">
                <tr>
                  <th>参数设置时间</th>
                  <th>模式</th>
                  <th>减重</th>
                  <th>速度</th>
                  <th>助力</th>
                  <th>阻力</th>
                  <th>避障</th>
                  <th>训练时长</th>
                </tr>
                <tr
                  v-for="(m, demoIndex) in ReportList[index].WalkReportList"
                  :key="demoIndex"
                  :class="m.Model === m.ModelChange ? '' : 'bg'"
                >
                  <td class="">{{ m.Time }}</td>
                  <td :class="m.ModelDec === 'Underline' ? 'td-item' : ''">{{ m.Model }}</td>
                  <td :class="m.WeightDec === 'Underline' ? 'td-item': ''">{{ m.Weight }}</td>
                  <td :class="m.FollowSpeedDecDec === 'Underline' ? 'td-item': ''">{{ m.Speed }}</td>
                  <td :class="m.AssistanceDec === 'Underline' ? 'td-item': ''">{{ m.Assistance }}</td>
                  <td :class="m.ResistanceDec === 'Underline' ? 'td-item': ''">{{ m.Resistance }}</td>
                  <td :class="m.AvoidDec === 'Underline' ? 'td-item': ''">{{ m.Avoid }}</td>
                  <td :class="m.TotalTimeDec === 'Underline' ? 'td-item': ''">{{ m.TotalTime }}</td>
                </tr>
              </table>
              <!-- 当打印的时候应该消失 -->
              <!-- <div class="closeBtn">
                <span>收起</span>
                <img src="@/assets/report/pointIcon.png" alt="" />
              </div> -->
              <!-- 这个是结论 -->
              <div class="result-table">
                <table>
                  <tr>
                    <td>模式：{{ ReportList[index].WalkMode === 0 ? 
                      '跟随' : ReportList[index].WalkMode === 1 ? 
                      '阻力' : ReportList[index].WalkMode === 2 ? 
                      '助力' :ReportList[index].WalkMode === 15? 
                      '单关节主动' : '单关节被动'}}</td>
                    <td>
                      训练距离：{{ ReportList[index].WTrainingWalkdistance }} m
                    </td>
                    <td>定时：{{ ReportList[index].WTime }} min</td>
                  </tr>
                  <tr>
                    <td>
                      减重：{{ ReportList[index].WImponderability }} kg
                    </td>
                    <!-- <td>得分：{{}}</td> -->
                    <!-- <td></td> -->
                    <td>
                      <!-- 训练时间：<span v-if="parseInt(ReportList[index].WTrainingWalktime / 60) !== 0">{{ parseInt(ReportList[index].WTrainingWalktime / 60) }} min</span><span v-if="parseInt(ReportList[index].WTrainingWalktime % 60) !== 0">{{ ReportList[index].WTrainingWalktime % 60 }} s</span><span v-if="ReportList[index].WTrainingWalktime === 0">{{ReportList[index].WTrainingWalktime}} s</span> -->
                      训练时间：{{ ReportList[index].WTrainingWalktime }} min
                    </td>
                    <td v-if="ReportList[index].WalkMode === 0" >
                      速度：{{ parseFloat(ReportList[index].WForce) }} mm/s
                    </td>
                     <td v-if="ReportList[index].WalkMode === 2" >
                      助力：{{ ReportList[index].WForce }} N
                    </td>
                     <td v-if="ReportList[index].WalkMode === 1" >
                      阻力：{{ ReportList[index].WForce }} N
                    </td>
                  </tr>
                  <tr>
                    <td>
                      平均步宽：{{ ReportList[index].WStepWidAgv.toFixed(1) }} mm
                    </td>
                    <td>平均步长时间：{{ ReportList[index].WStepTAgv.toFixed(1) }} s</td>
                    <td>平均步长：{{ ReportList[index].WStepLenAgv.toFixed(1) }} mm</td>
                  </tr>
                  <tr>
                    <td>平均站立时间：{{ ReportList[index].WStandAgv.toFixed(1)}} s</td>
                    <td>平均步频：{{ isFinite(ReportList[index].WStepFreAgv) ? ReportList[index].WStepFreAgv.toFixed(1) : 0 }}</td>
                    <td>平均摆动时间：{{ ReportList[index].WSwingAgv.toFixed(1) }} s</td>
                  </tr>
                  <tr>
                    <td>
                      平均双支撑时间：{{ ReportList[index].WDoubleAgv.toFixed(1) }} s
                    </td>
                    <!-- <td>平均步频：</td> -->
                  </tr>
                  <!-- <tr>
						   <td colspan='3'>平均双支撑时间：</td>
					   </tr> -->
                </table>
              </div>
              <!-- 最后是绘图,需要进行判断 -->
              <div>
                <div class="picture-content" v-if="!walkExsist">
                <div
                  style="width: 400px; height: 400px"
                  class="first"
                  id="first"
                  ref="first"
                ></div>
                <div
                  style="width: 400px; height: 400px"
                  class="second"
                  id="second"
                  ref="second"
                ></div>
                <div
                  class="third"
                  style="width: 400px; height: 400px"
                  ref="third"
                  id="third"
                ></div>
                <div
                  class="forth"
                  style="width: 400px; height: 400px"
                  ref="forth"
                  id="forth"
                ></div>
                <div
                  class="firth"
                  style="width: 400px; height: 400px"
                  ref="firth"
                  id="firth"
                ></div>
                <div
                  class="sixth"
                  style="width: 400px; height: 400px"
                  ref="sixth"
                  id="sixth"
                ></div>
              </div>
              </div>
            </div>
          </div>
          <!-- 坐站 -->
          <div class="sitAndStand" v-if="item === '2'">
            <!-- <div>{{ReportList[index]}}</div> -->
            <div class="content-title">
              <span>坐站训练</span>
              <div></div>
            </div>
            <div class="container">
              <img src="@/assets/report/form.png" alt="" />
            </div>
            <div class="sit-table">
              <table class="table-content">
                <tr>
                  <th>参数设置时间</th>
                  <th>模式</th>
                  <th>减重</th>
                  <th>体重</th>
                  <th>速度</th>
                  <th>坐姿时间</th>
                  <th>站姿时间</th>
                  <th>训练时长</th>
                </tr>
                <tr
                  v-for="(item, index) in ReportList[index].SitReportList"
                  :key="index"
                  :class="item.Model === item.ModelChange ? '' : 'bg'"
                >
                  <td>{{ item.Time }}</td>
                  <td :class="item.ModelDec === 'Underline' ? 'td-item' : ''">{{ item.Model }}</td>
                  <td :class="item.WeightHossDec === 'Underline' ? 'td-item' : ''">{{ item.WeightHoss }}</td>
                  <td :class="item.WeightDec === 'Underline' ? 'td-item' : ''">{{ item.Weight }}</td>
                  <td :class="item.SpeedDec === 'Underline' ? 'td-item' : ''">{{ item.Speed }}</td>
                  <td :class="item.SitTimeDec === 'Underline' ? 'td-item' : ''">{{ item.SitTime }}</td>
                  <td :class="item.StandTimeDec === 'Underline' ? 'td-item' : ''">{{ item.StandTime }}</td>
                  <td :class="item.TotalTimeDec === 'Underline' ? 'td-item' : ''">{{ item.TotalTime }}</td>
                </tr>
              </table>
              <div class="result-content">
                <table>
                  <tr>
                    <td>
                      模式：{{
                        ReportList[index].SSMode === 0 ? "被动" : "主动"
                      }}
                    </td>
                    <td>训练个数：{{ ReportList[index].SNumber }} 个</td>
                    <td>定时：{{ parseInt(ReportList[index].STime) }} min</td>
                  </tr>
                  <tr>
                    <td>
                      减重：{{ parseInt(ReportList[index].SImponderability) }}
                      kg
                    </td>
                    <td>
                      单个最短坐站时间：{{ ReportList[index].SrepCircleTimeMin }} s
                    </td>
                    <td>
                      <!-- 训练时间：<span v-if="parseInt(ReportList[index].STrainingSitTime / 60) !== 0">{{ parseInt(ReportList[index].STrainingSitTime / 60) }} min</span><span v-if="parseInt(ReportList[index].STrainingSitTime % 60) !== 0">{{ ReportList[index].STrainingSitTime % 60 }} s</span><span v-if="ReportList[index].STrainingSitTime === 0">{{ReportList[index].STrainingSitTime}} s</span> -->
                      训练时间：{{ ReportList[index].STrainingSitTime }} min
                    </td>
                  </tr>
                  <tr>
                    <td>
                      平均坐站时间：{{ ReportList[index].SrepCircleTimeAvg }} s
                    </td>
                    <!-- <td></td> -->
                    <td>
                      单个最长坐站时间：{{ReportList[index].SrepCircleTimeMax}} s
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="picture-content" v-if="!sitExsist">
              <div
                style="width: 400px; height: 400px"
                class="sit_forth"
                id="sit_forth"
                ref="sit_forth"
              ></div>
              <div
                style="width: 400px; height: 400px"
                class="sit_fifth"
                id="sit_fifth"
                ref="sit_fifth"
              ></div>
              <div
                style="width: 400px; height: 400px"
                class="sit_sixth"
                id="sit_sixth"
                ref="sit_sixth"
              ></div>
              <div
                style="width: 400px; height: 400px"
                class="sit_seventh"
                id="sit_seventh"
                ref="sit_seventh"
              ></div>
              <div
                style="width: 400px; height: 400px"
                class="sit_eigth"
                id="sit_eigth"
                ref="sit_eigth"
              ></div>
              <div
                style="width: 400px; height: 400px"
                class="sit_ninth"
                id="sit_ninth"
                ref="sit_ninth"
              ></div>
            </div>
            <div class="sitParameters">
              <table>
                <tr>
                  <th>ID</th>
                  <th>坐站参数</th>
                  <th>测试结果</th>
                  <th>正常值</th>
                </tr>
                <tr>
                  <td>1</td>
                  <td>坐-站时间(s)</td>
                  <td style="color: red">{{ ReportList[index].StableSSTimeUp }}</td>
                  <td>2.60±0.32</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>站-坐时间(s)</td>
                  <td style="color: red">{{ ReportList[index].StableSSTimeDown }}</td>
                  <td>2.81±0.36</td>
                </tr>
              </table>
            </div>
          </div>
          <!-- 游戏 -->
          <div class="game-content" v-if="item === '3'" >
            <div class="content-title">
              <span>游戏训练</span>
              <div></div>
            </div>
            <div class="table-game">
              <table>
                <tr>
                  <td>
                    类型：{{
                      ReportList[index].GameMode === 3
                        ? "宇宙拼图"
                        : ReportList[index].GameMode === 9
                        ? "汪汪天使"
                        : ReportList[index].GameMode === 10
                        ? "打地鼠"
                        : ReportList[index].GameMode === 11
                        ? "跑酷猫"
                        : ReportList[index].GameMode === 12
                        ? "梦幻森林"
                        : ReportList[index].GameMode === 13
                        ? "太空漫步"
                        : ReportList[index].GameMode === 14
                        ? "循迹"
                        : ReportList[index].GameMode === 10
                        ? "打地鼠"
                        : ReportList[index].GameMode === 23
                        ? "欢乐盖大楼" 
                        : ReportList[index].GameMode === 4
                        ? "太空飞行" : '无'
                    }}
                  </td>
                  <td>
                    游戏难度：{{
                      ReportList[index].GDifficulty === 1
                        ? "易"
                        : ReportList[index].GDifficulty === 2
                        ? "中"
                        : ReportList[index].GDifficulty === 3
                        ?"难" : ''
                    }}
                  </td>
                  <td>评分：{{ ReportList[index].GScore }}</td>
                </tr>
                <tr>
                  <td>定时：{{ ReportList[index].GTime }} min</td>
                  <!-- <td>训练时间：<span v-if="parseInt(ReportList[index].GTrainingTime / 60) !== 0">{{ parseInt(ReportList[index].GTrainingTime / 60) }} min </span><span v-if="ReportList[index].GTrainingTime % 60 !== 0">{{ ReportList[index].GTrainingTime % 60 }} s</span><span v-if="ReportList[index].GTrainingTime === 0">{{ReportList[index].GTrainingTime}} s</span></td> -->
                  <td>训练时间：{{ ReportList[index].GTrainingTime }} min</td>
                </tr>
              </table>
            </div>
            <div class="game-echarts" v-if="!gameExsist">
                <div
                class="game"
                id="game"
                ref="game"
                ></div>
            </div>
          </div>
          <!-- 平衡评估 -->
          <div class="blance-content" v-if="item === '4'">
            <div class="content-title">
              <span v-if="ReportList[index].cLBalanceVision !== null">平衡-视觉测试</span>
              <span v-if="ReportList[index].cLBalanceVesti !== null">平衡-前庭感觉</span>
              <span v-if="ReportList[index].cLBalanceNou !== null">平衡-本体感觉</span>
              <span v-if="ReportList[index].cLBalanceStab !== null">平衡-稳定极限</span>
              <div></div>
            </div>
            <div class="blance-info">
              <!-- 这个内容要确定一下各部分的内容 -->
              <!-- 视觉 -->
              <table v-if="ReportList[index].cLBalanceVision !== null">
                <tr>
                  <td>睁眼测试</td>
                  <td></td>
                  <td>闭眼测试</td>
                  <td></td>
                </tr>
                <tr>
                  <td>轨迹长度：{{ ReportList[index].cLBalanceVision.OpenLength.toFixed(1) }} mm</td>
                  <td></td>
                  <td>轨迹长度：{{ ReportList[index].cLBalanceVision.CloseLength.toFixed(1) }} mm</td>
                  <td></td>
                </tr>
                <tr>
                  <td>包络面积：{{ ReportList[index].cLBalanceVision.OpenArea.toFixed(1) }} mm</td>
                  <td></td>
                  <td>包络面积：{{ ReportList[index].cLBalanceVision.CloseArea.toFixed(1) }} mm</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Romberg率： {{ ReportList[index].cLBalanceVision.RombergLength }}%</td>
                  <td></td>
                  <td>Romberg率： {{ ReportList[index].cLBalanceVision.RombergArea }}%</td>
                  <td></td>
                </tr>
              </table>
              <!-- 平衡前庭感知 -->
              <table v-if="ReportList[index].cLBalanceVesti !== null">
                <tr>
                  <td>前庭感觉测试结果</td>
                  <!-- <td></td> -->
                </tr>
                <tr>
                  <td>轨迹长度：{{ ReportList[index].cLBalanceVesti.VestiLength.toFixed(1) }} mm</td>
                  <td></td>
                  <td>最大摆幅(左右)：{{ ReportList[index].cLBalanceVesti.VestiMaxSwingX.toFixed(1) }} mm</td>
                  <td></td>
                </tr>
                <tr>
                  <td>包络面积：{{ Math.floor(ReportList[index].cLBalanceVesti.VestiArea.toFixed(1)) }} mm²</td>
                  <td></td>
                  <td>最大摆幅(前后)：{{ Math.floor(ReportList[index].cLBalanceVesti.VestiMaxSwingY.toFixed(1)) }} mm</td>
                  <td></td>
                </tr>
                <tr>
                  <td>平均摆速：{{ Math.floor(ReportList[index].cLBalanceVesti.VestiAvgSpeed.toFixed(1)) }} mm/s</td>
                  <td></td>
                  <td>平均摆幅：{{ Math.floor(ReportList[index].cLBalanceVesti.VestiAvgSwing.toFixed(1)) }} mm</td>
                  <td></td>
                </tr>
              </table>
              <!-- 平衡本体感知 -->
              <table v-if="ReportList[index].cLBalanceNou !== null">
                <tr>
                  <td>本体感觉</td>
                  <td>健侧测试</td>
                  <td>患侧测试</td>
                  <td>双脚测试</td>
                </tr>
                <tr>
                  <td>轨迹长度(mm)</td>
                  <td>{{ ReportList[index].cLBalanceNou.NouHealthLength.toFixed(1) }}</td>
                  <td>{{ ReportList[index].cLBalanceNou.NouBoothLength.toFixed(1) }}</td>
                  <td>{{ ReportList[index].cLBalanceNou.NouUnHealthLength.toFixed(1) }}</td>
                </tr>
                <tr>
                  <td>包络面积(mm²)</td>
                  <td>{{ ReportList[index].cLBalanceNou.NouHealthArea.toFixed(1) }}</td>
                  <td>{{ ReportList[index].cLBalanceNou.NouBoothArea.toFixed(1) }}</td>
                  <td>{{ ReportList[index].cLBalanceNou.NouUnHealthArea.toFixed(1) }}</td>
                </tr>
                <tr>
                  <td>平均摆速(mm/s)</td>
                  <td>{{ ReportList[index].cLBalanceNou.NouHealthAvgSpeed.toFixed(1) }}</td>
                  <td>{{ ReportList[index].cLBalanceNou.NouUnHealthAvgSpeed.toFixed(1) }}</td>
                  <td>{{ ReportList[index].cLBalanceNou.NouBoothAvgSpeed.toFixed(1) }}</td>
                </tr>
                <tr>
                  <td>平均摆幅(mm)</td>
                  <td>{{ ReportList[index].cLBalanceNou.NouHealthAvgSwing.toFixed(1) }}</td>
                  <td>{{ ReportList[index].cLBalanceNou.NouUnHealthAvgSwing.toFixed(1) }}</td>
                  <td>{{ ReportList[index].cLBalanceNou.NouBoothAvgSwing.toFixed(1) }}</td>
                </tr>
                <tr>
                  <td>左右最大摆幅(mm)</td>
                  <td>{{ ReportList[index].cLBalanceNou.NouHealthMaxSwingX.toFixed(1) }}</td>
                  <td>{{ ReportList[index].cLBalanceNou.NouUnHealthMaxSwingX.toFixed(1) }}</td>
                  <td>{{ ReportList[index].cLBalanceNou.NouBoothMaxSwingX.toFixed(1) }}</td>
                </tr>
                <tr>
                  <td>前后最大摆幅(mm)</td>
                  <td>{{ ReportList[index].cLBalanceNou.NouHealthMaxSwingY.toFixed(1) }}</td>
                  <td>{{ ReportList[index].cLBalanceNou.NouUnHealthMaxSwingY.toFixed(1) }}</td>
                  <td>{{ ReportList[index].cLBalanceNou.NouBoothMaxSwingY.toFixed(1) }}</td>
                </tr>
              </table>
              <table v-if="ReportList[index].cLBalanceStab !== null">
                <tr>
                  <td colspan="3">测试结果</td>
                </tr>
                <tr>
                  <td colspan="3">
                    稳定极限范围：{{ ReportList[index].cLBalanceStab.StabilityField.toFixed(1) }} mm
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <!-- 单关节行走训练 -->
          <div class="walk-info" v-if="item === '5'">
            <div class="content-title">
              <span>单关节行走训练</span>
              <div></div>
            </div>
            <div class="tips-info">
              <img src="@/assets/report/form.png" alt="" />
            </div>
            <div class="walk—table">
              <table class="table-content">
                <tr>
                  <th>参数设置时间</th>
                  <th>模式</th>
                  <th>减重</th>
                  <th>速度</th>
                  <th>助力</th>
                  <th>阻力</th>
                  <th>避障</th>
                  <th>训练时长</th>
                </tr>
                <tr
                  v-for="(m, demoIndex) in ReportList[index].WalkReportList"
                  :key="demoIndex"
                  :class="m.Model === m.ModelChange ? '' : 'bg'"
                >
                  <td class="">{{ m.Time }}</td>
                  <td :class="m.ModelDec === 'Underline' ? 'td-item' : ''">{{ m.Model }}</td>
                  <td :class="m.WeightDec === 'Underline' ? 'td-item': ''">{{ m.Weight }}</td>
                  <td :class="m.FollowSpeedDecDec === 'Underline' ? 'td-item': ''">{{ m.Speed }}</td>
                  <td :class="m.AssistanceDec === 'Underline' ? 'td-item': ''">{{ m.Assistance }}</td>
                  <td :class="m.ResistanceDec === 'Underline' ? 'td-item': ''">{{ m.Resistance }}</td>
                  <td :class="m.AvoidDec === 'Underline' ? 'td-item': ''">{{ m.Avoid }}</td>
                  <td :class="m.TotalTimeDec === 'Underline' ? 'td-item': ''">{{ m.TotalTime }}</td>
                </tr>
              </table>
              <div class="result-table">
                <table>
                  <tr>
                    <td>模式：{{ ReportList[index].WalkMode === 16? 
                      '单关节主动' : '单关节被动'}}</td>
                    <td>
                      训练距离：{{ ReportList[index].WTrainingWalkdistance }} m
                    </td>
                    <td>定时：{{ ReportList[index].WTime }} min</td>
                  </tr>
                  <tr>
                    <td>
                      减重：{{ ReportList[index].WImponderability }} kg
                    </td>
                    <td>
                      <!-- 训练时间：<span v-if="parseInt(ReportList[index].WTrainingWalktime / 60) !== 0">{{ parseInt(ReportList[index].WTrainingWalktime / 60) }} min</span><span v-if="parseInt(ReportList[index].WTrainingWalktime % 60) !== 0">{{ ReportList[index].WTrainingWalktime % 60 }} s</span><span v-if="ReportList[index].WTrainingWalktime === 0">{{ReportList[index].WTrainingWalktime}} s</span> -->
                      训练时间：{{ ReportList[index].WTrainingWalktime }} min
                    </td>
                    <td v-if="ReportList[index].WalkMode === 0" >
                      速度：{{ parseFloat(ReportList[index].WForce) }} mm/s
                    </td>
                     <td v-if="ReportList[index].WalkMode === 2" >
                      助力：{{ ReportList[index].WForce }} N
                    </td>
                     <td v-if="ReportList[index].WalkMode === 1" >
                      阻力：{{ ReportList[index].WForce }} N
                    </td>
                  </tr>
                  <tr>
                    <td>
                      平均步宽：{{ ReportList[index].WStepWidAgv.toFixed(1) }} mm
                    </td>
                    <td>平均步长时间：{{ ReportList[index].WStepTAgv.toFixed(1) }} s</td>
                    <td>平均步长：{{ ReportList[index].WStepLenAgv.toFixed(1) }} mm</td>
                  </tr>
                  <tr>
                    <td>平均站立时间：{{ ReportList[index].WStandAgv.toFixed(1)}} s</td>
                    <td>平均步频：{{ isFinite(ReportList[index].WStepFreAgv) ? ReportList[index].WStepFreAgv.toFixed(1) : 0 }}</td>
                    <td>平均摆动时间：{{ ReportList[index].WSwingAgv.toFixed(1) }} s</td>
                  </tr>
                  <tr>
                    <td>
                      平均双支撑时间：{{ ReportList[index].WDoubleAgv.toFixed(1) }} s
                    </td>
                  </tr>
                </table>
              </div>
              <div>
                <div class="picture-content" v-if="!singleWalkExsist">
                <div
                  style="width: 400px; height: 400px"
                  class="singlefirst"
                  id="firstSingle"
                  ref="firstSingle"
                ></div>
                <div
                  style="width: 400px; height: 400px"
                  class="singlesecond"
                  id="secondSingle"
                  ref="secondSingle"
                ></div>
                <div
                  class="singlethird"
                  style="width: 400px; height: 400px"
                  ref="thirdSingle"
                  id="thirdSingle"
                ></div>
                <div
                  class="singleforth"
                  style="width: 400px; height: 400px"
                  ref="forthSingle"
                  id="forthSingle"
                ></div>
                <div
                  class="singlefirth"
                  style="width: 400px; height: 400px"
                  ref="firthSingle"
                  id="firthSingle"
                ></div>
                <div
                  class="singlesixth"
                  style="width: 400px; height: 400px"
                  ref="sixthSingle"
                  id="sixthSingle"
                ></div>
              </div>
              </div>
            </div>
          </div>
          <!-- 单关节坐站训练 -->
          <div class="sitAndStand" v-if="item === '6'">
            <div class="content-title">
              <span>单关节坐站训练</span>
              <div></div>
            </div>
            <div class="container">
              <img src="@/assets/report/form.png" alt="" />
            </div>
            <div class="sit-table">
              <table class="table-content">
                <tr>
                  <th>参数设置时间</th>
                  <th>模式</th>
                  <th>减重</th>
                  <th>体重</th>
                  <th>速度</th>
                  <th>坐姿时间</th>
                  <th>站姿时间</th>
                  <th>训练时长</th>
                </tr>
                <tr
                  v-for="(item, index) in ReportList[index].SitReportList"
                  :key="index"
                  :class="item.Model === item.ModelChange ? '' : 'bg'"
                >
                  <td>{{ item.Time }}</td>
                  <td :class="item.ModelDec === 'Underline' ? 'td-item' : ''">{{ item.Model }}</td>
                  <td :class="item.WeightHossDec === 'Underline' ? 'td-item' : ''">{{ item.WeightHoss }}</td>
                  <td :class="item.WeightDec === 'Underline' ? 'td-item' : ''">{{ item.Weight }}</td>
                  <td :class="item.SpeedDec === 'Underline' ? 'td-item' : ''">{{ item.Speed }}</td>
                  <td :class="item.SitTimeDec === 'Underline' ? 'td-item' : ''">{{ item.SitTime }}</td>
                  <td :class="item.StandTimeDec === 'Underline' ? 'td-item' : ''">{{ item.StandTime }}</td>
                  <td :class="item.TotalTimeDec === 'Underline' ? 'td-item' : ''">{{ item.TotalTime }}</td>
                </tr>
              </table>
              <div class="result-content">
                <table>
                  <tr>
                    <td>
                      模式：{{
                        ReportList[index].SSMode === 0 ? "被动" : "主动"
                      }}
                    </td>
                    <td>训练个数：{{ ReportList[index].SNumber }} 个</td>
                    <td>定时：{{ parseInt(ReportList[index].STime) }} min</td>
                  </tr>
                  <tr>
                    <td v-if="ReportList[index].SSMode === 1">
                      减重：{{ parseInt(ReportList[index].SImponderability) }}
                      kg
                    </td>
                    <td>
                      单个最短坐站时间：{{ ReportList[index].SrepCircleTimeMin }} s
                    </td>
                    <td>
                      <!-- 训练时间：<span v-if="parseInt(ReportList[index].STrainingSitTime / 60) !== 0">{{ parseInt(ReportList[index].STrainingSitTime / 60) }} min</span><span v-if="parseInt(ReportList[index].STrainingSitTime % 60) !== 0">{{ ReportList[index].STrainingSitTime % 60 }} s</span><span v-if="ReportList[index].STrainingSitTime === 0">{{ReportList[index].STrainingSitTime}} s</span> -->
                      训练时间：{{ ReportList[index].STrainingSitTime }} min
                    </td>
                    <td v-if="ReportList[index].SSMode === 0">
                      平均坐站时间：{{ ReportList[index].SrepCircleTimeAvg }} s
                    </td>
                  </tr>
                  <tr>
                    <td v-if="ReportList[index].SSMode === 1">
                      平均坐站时间：{{ ReportList[index].SrepCircleTimeAvg }} s
                    </td>
                    <td>
                      单个最长坐站时间：{{ReportList[index].SrepCircleTimeMax}} s
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="picture-content" v-if="!singleSitExsist">
              <div
                style="width: 400px; height: 400px"
                class="singleSitForth"
                id="sitSingleForth"
                ref="sitSingleForth"
              ></div>
              <div
                style="width: 400px; height: 400px"
                class="singleSitFifth"
                id="sitSignleFifth"
                ref="sitSingleFifth"
              ></div>
              <div
                style="width: 400px; height: 400px"
                class="singleSitSixth"
                id="sitSingleSixth"
                ref="sitSingleSixth"
              ></div>
              <div
                style="width: 400px; height: 400px"
                class="singleSitSeventh"
                id="sitSingleSeventh"
                ref="sitSingleSeventh"
              ></div>
              <div
                style="width: 400px; height: 400px"
                class="singleSitEigth"
                id="sitSingleEigth"
                ref="sitSingleEigth"
              ></div>
              <div
                style="width: 400px; height: 400px"
                class="singleSitNinth"
                id="sitSingleNinth"
                ref="sitSingleNinth"
              ></div>
            </div>
            <div class="sitParameters">
              <table>
                <tr>
                  <th>ID</th>
                  <th>坐站参数</th>
                  <th>测试结果</th>
                  <th>正常值</th>
                </tr>
                <tr>
                  <td>1</td>
                  <td>坐-站时间(s)</td>
                  <td style="color: red">{{ ReportList[index].StableSSTimeUp }}</td>
                  <td>2.60±0.32</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>站-坐时间(s)</td>
                  <td style="color: red">{{ ReportList[index].StableSSTimeDown }}</td>
                  <td>2.81±0.36</td>
                </tr>
              </table>
            </div>
          </div>
          <!-- 关节训练 -->
          <!-- <div v-for='(i, index) in ReportProcess' :key='index'>
          <component :is="i === '1'? 'Walk': i === '2'? 'SitAndStand': i === '3'? 'Game': 'Balance'" :data=ReportList[index] :planId='planid'></component>
        </div> -->
        </div>
      </div>
    </div>
    <div class="report-demo" v-else>
      <div class="text">
        <p>训练已结束</p>
        <p>很抱歉，该训练暂无报告</p>
      </div>
      <div class="img">
        <img src="@/assets/report/blank.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
import jspdf from 'jspdf'
import {BASE_URL} from '../../config/baseUrl'
// import process from '../../config/prod.env'
export default {
  name: "IndexPage",
  data() {
    return {
      imgUrl:'',
      list: {},
      planid: '',
      id: '',
      reporttime: '',
      ReportProcess:'',
      isOk:"",
      res:{},
      ReportList:[],
      walkData:[],
      singleWalkData:[],
      sitAndStandData:[],
      singleSitAndStandData:[],
      gameData:[],
      balanceData:[],
      walkExsist: true,
      singleWalkExsist: true,
      singleSitExsist: true,
      sitExsist: true,
      gameExsist: true,
      balanceExsist: true,
      showApp:true,
      isVisiable: true,
      destoryContent: false,
      serialNumber: '',
      phone: '',
      details: '',
      age:'',
      reportData: '',
      indexNo: 0,
      firstVar: false,
      secondVar: false,
      thirdVar: false,
      isVRGame: false,
    };
  },
  async created(){
    // const BASE_URL = "http://192.168.8.20:8001/netcore"

    this.planid = this.getQueryVariable("planid");
    this.id = this.getQueryVariable("id");
    if(this.planid === '' && this.id === ''){
      window.location.href = "https://www.ga-robot.com"
    }
    // this.planid = this.getQueryVariable('planid');
    // this.serialNumber = this.getQueryVariable('serialnumber');
    // this.phone = this.getQueryVariable('phone');
    // setTimeout(()=>{
    // //    this.axios.get(`mp/api/userinfo?phone=${this.phone}&serialNumber=${this.serialNumber}&roleid=0&type=1`).then(res=>{
    // //   this.list = res.data.data
    // //   this.details = JSON.parse(this.list.userDetails)
    // // })
      
    // },1000)
    await this.getReportData();
    await this.initUserInfo();
    
    // await this.getReportFormData();
  },
  async mounted() {
    // this.planid = this.getQueryVariable("planid")
    // this.serialNumber = this.getQueryVariable("serialnumber")
    // this.phone = this.getQueryVariable("phone")
    // console.log(this.phone, this.serialNumber, this.planid)
    // 这个该怎么做？？只凭借一个id应该是不行吧，因为要区分哪个医院，加个设备序列号？
    // window.open('http://localhost:8080?planid=7913&id=1000138')
  },
  
  updated(){
      if (this.list.birthdaydate !== undefined && this.list.birthdaydate !== '') {
        this.age = this.getAge(this.reporttime,this.list.birthdaydate)
      }
    // 现在的情况是 没有雷达数据就
    // 怎么判断是否输出
    this.$nextTick(() => {
      if(!this.walkExsist || !this.sitExsist || !this.balanceExsist || !this.gameExsist || !this.singleWalkExsist || !this.singleSitExsist){
        this.initWalkEcharts(this.walkData)
        !this.sitExsist ? this.initSitAndStandEcharts(this.sitAndStandData) : ''
        !this.gameExsist ? this.initGameEchrts(this.gameData): ''
        this.initSingleWalkEcharts(this.singleWalkData)
        this.initSingleSitEcharts(this.singleSitAndStandData)
        if(this.firstVar && this.secondVar && this.thirdVar && !this.isVRGame){
          // console.log('1111')
          this.printpdf()
        }
      }
      else if(this.firstVar && this.secondVar && this.thirdVar && !this.isVRGame){
          // console.log('1111')
          this.printpdf()
      }
      // if(this.isVRGame) {
      //   // 就显示无报告提示
      //   // 然后不打印报告
      // }
    })
    
  },
  beforeDestroy(){
    clearTimeout()
  },
  methods:{
    // 初始化表格
    async getReportData(){
      // let _this = this;
      this.axios.get(`/netcore/api/GetReportData?reportid=${this.planid}`).then(res=>{
        console.log('res', res);
        if(res.data.CReport === null) {
          this.isVRGame = true;
        } else {
          this.res = res
          this.getReportFormData()
        }
        this.firstVar = true
    })
    },
    async getReportFormData(){
      // let _this = this;
      this.axios.get(`/netcore/api/GetReportFormData?planid=${this.planid}`).then((resData)=>{
        console.log('resData', resData);
        if(resData.data === []) {
          return
        }
      const { orderID } = resData.data
      // console.log('xxxRes', this.res.data)
      // console.log('this.res.data.CReport === null', this.res.data.CReport === null)
      // if(this.res.data.CReport === null) {
      //   return
      // }
      const { CReport,reporttime } = this.res.data;
      if(CReport === null){
        return;
      }
      // const { reportProcess, reportList } = CReport;
      const { ReportProcess, ReportList } = CReport;
      console.log(ReportProcess);
      console.log(ReportList);
      this.ReportProcess = ReportProcess
      this.ReportList = ReportList
      // this.reporttime = reportTime.split("T")[0]
      this.reporttime = reporttime.split("T")[0]
      this.reportData = resData.data.data
      for(var i = 0 ; i < orderID.length; i++){
        // i == 4 
        if(orderID[i] === 1){
         this.walkData.push(this.reportData[this.indexNo])
         for(let item in this.reportData[this.indexNo]){
          if(this.reportData[this.indexNo][item] !== null){
            this.walkExsist = false
          }
         }
         this.indexNo ++;
        } else if(orderID[i] === 2){
          this.sitAndStandData.push(this.reportData[this.indexNo])
          for(let item in this.reportData[this.indexNo]){
            if(this.reportData[this.indexNo][item] !== null){
              console.log(this.reportData[this.indexNo][item])
              this.sitExsist = false
            }
          }
          this.indexNo++;
        } else if(orderID[i] === 3){
            this.gameData.push(this.reportData[this.indexNo])
          for(let item in this.reportData[this.indexNo]){
            if(this.reportData[this.indexNo][item] !== null){
              this.gameExsist = false
            }
          }
          this.indexNo++;
          // gameData = data[i+1]
        } else if(orderID[i] === 4){
          this.balanceExsist = false;
          for(let item in this.reportData[i]){
            if(this.reportData[i][item] !== null){
              // this.balanceData[item] = this.reportData[i][item]
            }
          }
        } else if(orderID[i] === 5) {
          this.singleWalkData.push(this.reportData[this.indexNo])
          for(let item in this.reportData[this.indexNo]){
            if(this.reportData[this.indexNo][item] !== null) {
              // this.b
              this.singleWalkExsist = false;
            }
          }
          this.indexNo++;
          // 单关节坐站
        } else if(orderID[i] === 6) {
          console.log('66666' ,this.reportData[this.indexNo])
          this.singleSitAndStandData.push(this.reportData[this.indexNo])
          console.log('this.singleSitAndStandData', this.singleSitAndStandData)
          for(let item in this.reportData[this.indexNo]){
            if(this.reportData[this.indexNo][item] !== null){
              this.singleSitExsist = false;
            }
          }
          this.indexNo++;
        }
      }
      this.isOk = 'true'
      this.showApp = true
      this.secondVar = true
    }
   )
    },
    printpdf(){
      // 判断是否存在
      window.pageYOffset = 0;
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
      const domElement = document.getElementsByClassName('report-container')[0]
      var w = document.body.scrollWidth
      // let w = document.body.scrollWidth
      var h = document.body.scrollHeight
      var size = 'p'
      if(w > h){
        size = 'l'
      }
      html2canvas(domElement,{
        allowTaint: true,
        scale:1
      }).then((canvas)=>{
        const imgData = canvas.toDataURL('image/png')
        
        const pdf = new jspdf(size,'px',[w,h])
        pdf.addImage(imgData,'JPEG',0,0,w,h)
        var datauri = pdf.output('dataurlstring');
        var myfile = this.dataURLtoFile(datauri,'hhh.pdf')  // 转文件
        var formdata = new FormData()
        formdata.append('file',myfile)
        formdata.append('id',this.planid)
        const config = {
        headers:{"Content-Type": "multipart/form-data" }
        }
        const res = this.axios.post(`/netcore/api/saveReport`,formdata,config)
        console.log('res', res)
      })
    },
    closePage(){
      if(navigator.userAgent.indexOf('MSIE') > 0){
        if(navigator.userAgent.indexOf('MSIE 6.0') > 0){
          window.opener = null;
          window.close();
        }else{
          window.open('', '_top')
          window.location.href = "about:blank"
          window.close()
        }
      }else{
        window.opener = null
        window.open('','_self')
        window.close()
      }
    },
    uploadPdf(res){
      let pdfBase64Str = res
      var fileObj = this.dataURLtoFile(pdfBase64Str,this.planid + '.pdf');
      // console.log(fileObj,'fileObj --- 222')
      let fileName = fileObj.name;
      let pos = fileName.lastIndexOf(".")
      let lastName = fileName.substring(pos,fileName.length)
      let ossSavePath = "/zhaocai/" + lastName
      let result = this.multipartUpload(
        fileObj,
        ossSavePath,
        fileObj.uid,
        lastName
      );
      result.then((data) => {
        let str = {
          name: fileObj.name,
          path: data.url,
        };
      });
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    async multipartUpload(fileObj, ossSavePath, fileName, ext, fileRemark) {
      let uid = fileName;
      this.$set(this.processPercent, uid, 0);
      try {
        let self = this;
        let result = await this.client.multipartUpload(ossSavePath, fileObj, {
          progress: function (p, checkpoint) {
            self.$set(self.processPercent, uid, Math.floor(p * 100));
            // 断点记录点。浏览器重启后无法直接继续上传，您需要手动触发上传操作。
            self.tempCheckpoint = checkpoint;
          },
          meta: { year: 2020, people: "test" },
          mime: fileObj.type,
        });

        let data = {
          url: this.ossConfig.ossDomain + result.name,
          fileName: fileRemark,
          ext: ext,
        };
        return data;
      } catch (e) {
        console.log(e);
      }
    },
    async initUserInfo(){
      // return new Promise((reslove,reject) => {
      //   this.axios.get(`mp/api/userinfo?phone=${this.phone}&serialNumber=${this.serialNumber}&roleid=0&type=1`).then((res) => )
      // })
      setTimeout(() => {
        this.axios.get(`/netcore/mp/userinfo?type=1&patientid=${this.id}`).then(res=>{
          this.list = res.data.data
          console.log('resxxxx', typeof(this.list.birthdaydate))
          this.thirdVar = true
        })
      }, 500)
      
    },
    // 行走图生成
    initWalkEcharts(data){
      const first = [],second = [],third=[],four=[],five=[],six=[]
      for(let item = 0 ; item < data.length ; item++){
        // 步长 现在是第二列为空
        if(data[item].formDouble1 !== null && data[item].formDouble2 !== null){
        const contentLength = [400, 400, 394, 388, 394, 400, 400, 394, 394, 400, 400]
        const lengthTime = contentLength.length
        for(let i = 0 ; i < (data[item].formDouble1.length - lengthTime);i++){
          contentLength.push(contentLength[Math.floor(Math.random()*10)])
        }
        // 步宽
        const contentWidth = [200, 200, 197, 194, 197, 200, 200, 197, 197, 200, 200]
        const widthTime = contentWidth.length
        for(let i = 0 ; i < (data[item].formDouble2.length - widthTime);i++){
          contentWidth.push(contentWidth[Math.floor(Math.random()*10)])
        }
        // 步长时间
        const contentTime = [2, 2, 1.96, 1.92, 1.96, 2, 2, 1.96, 1.96, 2, 2]
        const walkTime = contentTime.length
        for(let i = 0 ; i < (data[item].formDouble3.length - walkTime);i++){
          contentTime.push(contentTime[Math.floor(Math.random()*10)])
        }
        // 双支撑时间
        const contentUpTime = [1, 1, 0.96, 0.92, 0.96, 1, 1, 0.96, 0.96, 1, 1]
        const upTime = contentUpTime.length
        for(let i = 0 ; i < (data[item].formDouble6.length - upTime);i++){
          contentUpTime.push(contentUpTime[Math.floor(Math.random()*10)])
        }
        // 摆动时间
        const contentSwingTime = [3.5, 3.5, 3.46, 3.42, 3.46, 3.5, 3.5, 3.46, 3.46, 3.5, 3.5]
        const SwingTime  = contentSwingTime.length
        for(let i = 0 ; i < (data[item].formDouble4.length - SwingTime);i++){
          contentSwingTime.push(contentSwingTime[Math.floor(Math.random()*10)])
        }
        // 站立时间
        const contentStandTime = [1, 1, 0.96, 0.92, 0.96, 1, 1, 0.96, 1, 1, 1]
        const standLength  = contentStandTime.length
        for(let i = 0 ; i < (data[item].formDouble5.length - standLength); i++){
          contentStandTime.push(contentStandTime[i])
        }
        var option = {
         legend: {
            // orient: 'verticalAlign',
            data: ['步长', '理论'],
            right: 'center',
            top: '0',
            icon: 'rect',
            itemHeight: 2,
         },
         boundaryGap: ['20%', '20%'],
         xAxis: {
            type: 'category',
            boundaryGap: false,
            axisTick: {
              show: false
            },
            axisLabel: {
              margin: 10,
              inteval: 10000,
              showMinLabel: true,
              showMaxLable: true
            }
         },
         yAxis: {
            type: 'value',
            name: '步长(mm)',
            nameLocation: 'end',
            nameGap: 30,
            min: 0,
            max: 500,
            nameTextStyle: {
               color: '#000',
               fontSize: 12,
            },
            axisLine: {
                show: true,
            }
         },
         series:[
          {
            name: '理论',
            type: 'line',
            // stack: '',
            data: contentLength,
            areaStyle: {
              color:'#DEF0FE'
            },
            smooth: true,
            animation: false,
            enableMouseTracking:false,
            shadow: false,
            symbol: "none" 
          },
         ]
        };
        var optionTwo = {
        legend: {
            // orient: 'verticalAlign',
            data: ['步宽', '理论'],
            right: 'center',
            top: '0',
            icon: 'rect',
            itemHeight: 2,
         },
        xAxis: {
          type: 'category',
            boundaryGap: false,
            axisTick: {
              show: false
            },
             axisLabel: {
              margin: 10,
              inteval: 10000,
              showMinLabel: true,
              showMaxLable: true
            }
        },
        yAxis: {
          type: 'value',
          name: '步宽(mm)',
          nameLocation: 'end',
          nameGap: 30,
          min: 0,
          max: 500,
          nameTextStyle: {
            color: '#000',
            fontSize: 12,
          },
          axisLine: {
            show: true,
          }
        },
        series:[
          {
            name: '理论',
            type: 'line',
            // stack: '',
            areaStyle:{
              color:'#DEF0FE'
            },
            data: contentWidth,
            smooth: true,
            animation: false,
            symbol: "none" 
          },
        ]
        };
        var optionThree = {
        legend: {
            // orient: 'verticalAlign',
            data: ['双支撑时间', '理论'],
            right: 'center',
            top: '0',
            icon: 'rect',
            itemHeight: 2,
         },
        xAxis: {
          type: 'category',
            boundaryGap: false,
            axisTick: {
              show: false
            },
        },
        yAxis: {
          type: 'value',
          name: '双支撑时间(s)',
          nameLocation: 'end',
          nameGap: 30,
          // min: 0,
          // max: 6,
          nameTextStyle: {
            color: '#000',
            fontSize: 12,
          },
          axisLine: {
             show: true,
          }
        },
        series:[
          {
            name: '理论',
            type: 'line',
            smooth: true,
            areaStyle:{
              color: '#DEF0FE'
            },
            // stack: '',
            data: contentUpTime,
            animation: false,
            symbol: "none" 
          },
        ]
        };
        var optionForth = {
        legend: {
            // orient: 'verticalAlign',
            data: ['站立时间', '理论'],
            // data: ['站立时间', '理论'],
            right: 'center',
            top: '0',
            icon: 'rect',
            itemHeight: 2,
         },
        xAxis: {
          type: 'category',
            boundaryGap: false,
            axisTick: {
              show: false
            },
        },
        yAxis: {
          type: 'value',
          name: '站立时间',
          // name: '站立时间(s)',
          nameLocation: 'end',
          nameGap: 30,
          nameTextStyle: {
            color: '#000',
            fontSize: 12,
          },
          // min: 0,
          // max: 6,
          axisLine: {
             show: true,
          }
        },
        series:[
           {
            name: '理论',
            type: 'line',
            smooth: true,
            // stack: '',
            // data: [1, 1, 0.96, 0.92, 0.96, 1, 1, 0.96, 0.96, 1, 1],
            areaStyle:{
              color:'#DEF0FE'
            },
            data:contentStandTime,
            animation: false,
            symbol: "none" 
          },
        ]
        };
        var optionFirth = {
        legend: {
            // orient: 'verticalAlign',
            data: ['步长时间', '理论'],
            // data: ['步长时间', '理论'],
            right: 'center',
            top: '0',
            icon: 'rect',
            itemHeight: 2,
         },
        xAxis: {
         type: 'category',
            boundaryGap: false,
            axisTick: {
              show: false
            },
        },
        yAxis: {
          type: 'value',
          // name: '步长时间(s)',
          name: '步长时间(s)',
          nameLocation: 'end',
          nameGap: 30,
          nameTextStyle: {
            color: '#000',
            fontSize: 12,
          },
          min: 0,
          max: 6,
          axisLine: {
             show: true,
          }
        },
        series:[
          {
            name: '理论',
            type: 'line',
            smooth: true,
            // stack: '',
            // data: [3.5, 3.5, 3.46, 3.42, 3.46, 3.5, 3.5, 3.46, 3.46, 3.5, 3.5],
            areaStyle:{
              color:'#DEF0FE'
            },
            data:contentTime,
            animation:false,
            symbol: "none" 
          },
        ]
        };
        var optionSixth = {
        legend: {
            // orient: 'verticalAlign',
            data: ['摆动时间', '理论'],
            // right: '0',
            right:'center',
            top: '0',
            icon: 'rect',
            itemHeight: 2,
         },
        xAxis: {
          type: 'category',
            boundaryGap: false,
            axisTick: {
              show: false
            },
        },
        yAxis: {
          type: 'value',
          // name: '摆动时间(s)',
          name: '摆动时间(s)',
          nameLocation: 'end',
          nameGap: 30,
          nameTextStyle: {
            color: '#000',
            fontSize: 12,
          },
          min: 0,
          max: 6,
          axisLine: {
             show: true,
          }
        },
        series:[
          {
            name: '理论',
            type: 'line',
            smooth: true,
            areaStyle:{
              color:'#DEF0FE'
            },
            // stack: '',
            // data: [1, 2, 0.96, 0.92, 0.96, 1, 1, 0.96, 1, 1, 1],
            data:contentSwingTime,
            animation:false,
            symbol: "none" 
          },
        ]
        };
            option.series.push({
              name:'步长',
              data:data[item].formDouble1,
              type:'line',
              color:'#E69570',
              areaStyle:{
                color:'#FDDEC1'
              },
              smooth: true,
              animation: false,
              enableMouseTracking:false,
              shadow: false,
              symbol: "none" 
           })
            optionTwo.series.push({
              name:'步宽',
              data: data[item].formDouble2,
              type:'line',
              color:'#E69570',
              smooth: true,
              animation:false,
              areaStyle:{
                color:'#FDDEC1'
              },
              enableMouseTracking:false,
              shadow:false,
              symbol: "none" 
            })
            optionThree.series.push({
              name:'步长时间',
              data:data[item].formDouble3,
              type:'line',
              smooth: true,
              animation: false,
              symbol: "none",
              color:'#E69570',
              areaStyle:{
                color: '#FDDEC1'
              }
            })
          // thirdChart.setOption(optionThree);
          // thirdChart.on('hover', function (params) {
          //  self.logShow = true;
          // });
            optionForth.series.push({
              name:'站立时间',
              data:data[item].formDouble6,
              type:'line',
              smooth: true,
              animation: false,
              symbol: "none",
              color:'#E69570',
              areaStyle:{
                color: '#FDDEC1'
              }
            })
      // forthChart.setOption(optionForth);
      // forthChart.on('hover', function (params) {
      //   self.logShow = true;
      // });
           optionFirth.series.push({
             name:'步长时间',
             data:data[item].formDouble4,
             type:'line',
             smooth: true,
             animation: false,
             symbol: "none",
             color:'#E69570',
             areaStyle:{
               color: '#FDDEC1'
             }
           })
      // fifthChart.setOption(optionFirth);
      // fifthChart.on('hover', function (params) {
      //   self.logShow = true;
      // });
          optionSixth.series.push({
            name:'摆动时间',
            data:data[item].formDouble5,
            type:'line',
            animation: false,
            smooth: true,
            symbol: "none",
            color:'#E69570',
            areaStyle:{
              color: '#FDDEC1'
            }
          })
      // sixthChart.setOption(optionSixth);
      // sixthChart.on('hover', function (params) {
      //   self.logShow = true;
      // });
        first[item] = this.$echarts.init(document.getElementsByClassName('first')[item]);
        second[item] = this.$echarts.init(document.getElementsByClassName('second')[item])
        third[item] = this.$echarts.init(document.getElementsByClassName('third')[item])
        four[item] = this.$echarts.init(document.getElementsByClassName('forth')[item])
        five[item] = this.$echarts.init(document.getElementsByClassName('firth')[item])
        six[item] = this.$echarts.init(document.getElementsByClassName('sixth')[item])
        first[item].setOption(option)
        second[item].setOption(optionTwo)
        third[item].setOption(optionThree)
        four[item].setOption(optionForth)
        five[item].setOption(optionFirth)
        six[item].setOption(optionSixth)
        }else{
          // 如果为空 那么就让他销毁内容
          const first = document.getElementsByClassName('first')[item]
          const second = document.getElementsByClassName('second')[item]
          const third = document.getElementsByClassName('third')[item]
          const forth = document.getElementsByClassName('forth')[item]
          const firth = document.getElementsByClassName('firth')[item]
          const sixth = document.getElementsByClassName('sixth')[item]
          first !== undefined ? first.style.display = "none" : '';
          second!== undefined ?second.style.display = "none": '';
          third!== undefined ?third.style.display = "none": '';
          forth!== undefined ?forth.style.display = "none": '';
          firth!== undefined ?firth.style.display = "none": '';
          sixth!== undefined ?sixth.style.display = "none": '';

          this.destoryContent = true
        }
      }
    },
    // 单关节行走生成
    initSingleWalkEcharts(data){
      const first = [],second = [],third=[],four=[],five=[],six=[];
      console.log('xxxxxxx', data)
      for(let item = 0 ; item < data.length ; item++){
        // 步长
        if(data[item].formDouble1 !== null){
        const contentLength = [400, 400, 394, 388, 394, 400, 400, 394, 394, 400, 400]
        const lengthTime = contentLength.length
        for(let i = 0 ; i < (data[item].formDouble1.length - lengthTime);i++){
          contentLength.push(contentLength[Math.floor(Math.random()*10)])
        }
        // 步宽
        const contentWidth = [200, 200, 197, 194, 197, 200, 200, 197, 197, 200, 200]
        const widthTime = contentWidth.length
        for(let i = 0 ; i < (data[item].formDouble2.length - widthTime);i++){
          contentWidth.push(contentWidth[Math.floor(Math.random()*10)])
        }
        // 步长时间
        const contentTime = [2, 2, 1.96, 1.92, 1.96, 2, 2, 1.96, 1.96, 2, 2]
        const walkTime = contentTime.length
        for(let i = 0 ; i < (data[item].formDouble3.length - walkTime);i++){
          contentTime.push(contentTime[Math.floor(Math.random()*10)])
        }
        // 双支撑时间
        const contentUpTime = [1, 1, 0.96, 0.92, 0.96, 1, 1, 0.96, 0.96, 1, 1]
        const upTime = contentUpTime.length
        for(let i = 0 ; i < (data[item].formDouble6.length - upTime);i++){
          contentUpTime.push(contentUpTime[Math.floor(Math.random()*10)])
        }
        // 摆动时间
        const contentSwingTime = [3.5, 3.5, 3.46, 3.42, 3.46, 3.5, 3.5, 3.46, 3.46, 3.5, 3.5]
        const SwingTime  = contentSwingTime.length
        for(let i = 0 ; i < (data[item].formDouble4.length - SwingTime);i++){
          contentSwingTime.push(contentSwingTime[Math.floor(Math.random()*10)])
        }
        // 站立时间
        const contentStandTime = [1, 1, 0.96, 0.92, 0.96, 1, 1, 0.96, 1, 1, 1]
        const standLength  = contentStandTime.length
        for(let i = 0 ; i < (data[item].formDouble5.length - standLength); i++){
          contentStandTime.push(contentStandTime[i])
        }
        var option = {
         legend: {
            // orient: 'verticalAlign',
            data: ['步长', '理论'],
            right: 'center',
            top: '0',
            icon: 'rect',
            itemHeight: 2,
         },
         boundaryGap: ['20%', '20%'],
         xAxis: {
            type: 'category',
            boundaryGap: false,
            axisTick: {
              show: false
            },
            axisLabel: {
              margin: 10,
              inteval: 10000,
              showMinLabel: true,
              showMaxLable: true
            }
         },
         yAxis: {
            type: 'value',
            name: '步长(mm)',
            nameLocation: 'end',
            nameGap: 30,
            min: 0,
            max: 500,
            nameTextStyle: {
               color: '#000',
               fontSize: 12,
            },
            axisLine: {
                show: true,
            }
         },
         series:[
          {
            name: '理论',
            type: 'line',
            // stack: '',
            data: contentLength,
            areaStyle: {
              color:'#DEF0FE'
            },
            smooth: true,
            animation: false,
            enableMouseTracking:false,
            shadow: false,
            symbol: "none" 
          },
         ]
        };
        var optionTwo = {
        legend: {
            // orient: 'verticalAlign',
            data: ['步宽', '理论'],
            right: 'center',
            top: '0',
            icon: 'rect',
            itemHeight: 2,
         },
        xAxis: {
          type: 'category',
            boundaryGap: false,
            axisTick: {
              show: false
            },
             axisLabel: {
              margin: 10,
              inteval: 10000,
              showMinLabel: true,
              showMaxLable: true
            }
        },
        yAxis: {
          type: 'value',
          name: '步宽(mm)',
          nameLocation: 'end',
          nameGap: 30,
          min: 0,
          max: 500,
          nameTextStyle: {
            color: '#000',
            fontSize: 12,
          },
          axisLine: {
            show: true,
          }
        },
        series:[
          {
            name: '理论',
            type: 'line',
            // stack: '',
            areaStyle:{
              color:'#DEF0FE'
            },
            data: contentWidth,
            smooth: true,
            animation: false,
            symbol: "none" 
          },
        ]
        };
        var optionThree = {
        legend: {
            // orient: 'verticalAlign',
            data: ['步长时间', '理论'],
            right: 'center',
            top: '0',
            icon: 'rect',
            itemHeight: 2,
         },
        xAxis: {
          type: 'category',
            boundaryGap: false,
            axisTick: {
              show: false
            },
        },
        yAxis: {
          type: 'value',
          name: '步长时间(s)',
          nameLocation: 'end',
          nameGap: 30,
          // min: 0,
          // max: 6,
          nameTextStyle: {
            color: '#000',
            fontSize: 12,
          },
          axisLine: {
             show: true,
          }
        },
        series:[
          {
            name: '理论',
            type: 'line',
            smooth: true,
            areaStyle:{
              color: '#DEF0FE'
            },
            // stack: '',
            data: contentTime,
            animation: false,
            symbol: "none" 
          },
        ]
        };
        var optionForth = {
        legend: {
            // orient: 'verticalAlign',
            data: ['站立时间', '理论'],
            right: 'center',
            top: '0',
            icon: 'rect',
            itemHeight: 2,
         },
        xAxis: {
          type: 'category',
            boundaryGap: false,
            axisTick: {
              show: false
            },
        },
        yAxis: {
          type: 'value',
          name: '站立时间(s)',
          nameLocation: 'end',
          nameGap: 30,
          nameTextStyle: {
            color: '#000',
            fontSize: 12,
          },
          // min: 0,
          // max: 6,
          axisLine: {
             show: true,
          }
        },
        series:[
           {
            name: '理论',
            type: 'line',
            smooth: true,
            // stack: '',
            // data: [1, 1, 0.96, 0.92, 0.96, 1, 1, 0.96, 0.96, 1, 1],
            areaStyle:{
              color:'#DEF0FE'
            },
            data:contentUpTime,
            animation: false,
            symbol: "none" 
          },
        ]
        };
        var optionFirth = {
        legend: {
            // orient: 'verticalAlign',
            data: ['步长时间', '理论'],
            right: 'center',
            top: '0',
            icon: 'rect',
            itemHeight: 2,
         },
        xAxis: {
         type: 'category',
            boundaryGap: false,
            axisTick: {
              show: false
            },
        },
        yAxis: {
          type: 'value',
          name: '步长时间(s)',
          nameLocation: 'end',
          nameGap: 30,
          nameTextStyle: {
            color: '#000',
            fontSize: 12,
          },
          min: 0,
          max: 6,
          axisLine: {
             show: true,
          }
        },
        series:[
          {
            name: '理论',
            type: 'line',
            smooth: true,
            // stack: '',
            // data: [3.5, 3.5, 3.46, 3.42, 3.46, 3.5, 3.5, 3.46, 3.46, 3.5, 3.5],
            areaStyle:{
              color:'#DEF0FE'
            },
            data:contentSwingTime,
            animation:false,
            symbol: "none" 
          },
        ]
        };
        var optionSixth = {
        legend: {
            // orient: 'verticalAlign',
            // data: ['站立时间', '理论'],
            data: ['摆动时间', '理论'],
            // right: '0',
            right:'center',
            top: '0',
            icon: 'rect',
            itemHeight: 2,
         },
        xAxis: {
          type: 'category',
            boundaryGap: false,
            axisTick: {
              show: false
            },
        },
        yAxis: {
          type: 'value',
          name: '摆动时间(s)',
          nameLocation: 'end',
          nameGap: 30,
          nameTextStyle: {
            color: '#000',
            fontSize: 12,
          },
          min: 0,
          max: 6,
          axisLine: {
             show: true,
          }
        },
        series:[
          {
            name: '理论',
            type: 'line',
            smooth: true,
            areaStyle:{
              color:'#DEF0FE'
            },
            // stack: '',
            // data: [1, 2, 0.96, 0.92, 0.96, 1, 1, 0.96, 1, 1, 1],
            data:contentStandTime,
            animation:false,
            symbol: "none" 
          },
        ]
        };
            option.series.push({
              name:'步长',
              data:data[item].formDouble1,
              type:'line',
              color:'#E69570',
              areaStyle:{
                color:'#FDDEC1'
              },
              smooth: true,
              animation: false,
              enableMouseTracking:false,
              shadow: false,
              symbol: "none" 
           })
            optionTwo.series.push({
              name:'步宽',
              data: data[item].formDouble2,
              type:'line',
              color:'#E69570',
              smooth: true,
              animation:false,
              areaStyle:{
                color:'#FDDEC1'
              },
              enableMouseTracking:false,
              shadow:false,
              symbol: "none" 
            })
            optionThree.series.push({
              name:'双支撑时间',
              data:data[item].formDouble3,
              type:'line',
              smooth: true,
              animation: false,
              symbol: "none",
              color:'#E69570',
              areaStyle:{
                color: '#FDDEC1'
              }
            })
          // thirdChart.setOption(optionThree);
          // thirdChart.on('hover', function (params) {
          //  self.logShow = true;
          // });
            optionForth.series.push({
              name:'站立时间',
              data:data[item].formDouble6,
              type:'line',
              smooth: true,
              animation: false,
              symbol: "none",
              color:'#E69570',
              areaStyle:{
                color: '#FDDEC1'
              }
            })
      // forthChart.setOption(optionForth);
      // forthChart.on('hover', function (params) {
      //   self.logShow = true;
      // });
           optionFirth.series.push({
             name:'步长时间',
             data:data[item].formDouble4,
             type:'line',
             smooth: true,
             animation: false,
             symbol: "none",
             color:'#E69570',
             areaStyle:{
               color: '#FDDEC1'
             }
           })
      // fifthChart.setOption(optionFirth);
      // fifthChart.on('hover', function (params) {
      //   self.logShow = true;
      // });
          optionSixth.series.push({
            name:'摆动时间',
            data:data[item].formDouble5,
            type:'line',
            animation: false,
            smooth: true,
            symbol: "none",
            color:'#E69570',
            areaStyle:{
              color: '#FDDEC1'
            }
          })
      // sixthChart.setOption(optionSixth);
      // sixthChart.on('hover', function (params) {
      //   self.logShow = true;
      // });
        first[item] = this.$echarts.init(document.getElementsByClassName('singlefirst')[item]);
        second[item] = this.$echarts.init(document.getElementsByClassName('singlesecond')[item])
        third[item] = this.$echarts.init(document.getElementsByClassName('singlethird')[item])
        four[item] = this.$echarts.init(document.getElementsByClassName('singleforth')[item])
        five[item] = this.$echarts.init(document.getElementsByClassName('singlefirth')[item])
        six[item] = this.$echarts.init(document.getElementsByClassName('singlesixth')[item])
        first[item].setOption(option)
        second[item].setOption(optionTwo)
        third[item].setOption(optionThree)
        four[item].setOption(optionForth)
        five[item].setOption(optionFirth)
        six[item].setOption(optionSixth)
        }else{
          // 如果为空 那么就让他销毁内容
          const first = document.getElementsByClassName('singlefirst')[item]
          const second = document.getElementsByClassName('singlesecond')[item]
          const third = document.getElementsByClassName('singlethird')[item]
          const forth = document.getElementsByClassName('singleforth')[item]
          const firth = document.getElementsByClassName('singlefirth')[item]
          const sixth = document.getElementsByClassName('singlesixth')[item]
          first !== undefined ? first.style.display = "none" : '';
          second!== undefined ?second.style.display = "none": '';
          third!== undefined ?third.style.display = "none": '';
          forth!== undefined ?forth.style.display = "none": '';
          firth!== undefined ?firth.style.display = "none": '';
          sixth!== undefined ?sixth.style.display = "none": '';

          this.destoryContent = true
        }
      }
    },

    // 生成坐站内容 initSitAndStandEcharts  initSingleSitEcharts
    initSingleSitEcharts(data){
      for(var x = 0 ; x < data.length ; x++){
        if(data[x].formFloat1 !== null && data[x].formInt5 !== null && data[x].formInt5.length > 1){
          let sider=[],speed=[],timer=[],leftSider=[],rightSider=[],leftSpeed=[],rightSpeed=[],
            leftTimer=[],rightTimer=[],status=[],avaSider=[],avaLeftSider=[],avaRightSider=[],avaLeftSpeed = [],avaRightSpeed = []
        const {formDouble4,formFloat3,formInt5} = data[x]
        let content = 0
        let demo = 0
        let sitForth = this.$echarts.init(document.getElementsByClassName("singleSitForth")[x]);
        
        let sitFifth = this.$echarts.init(document.getElementsByClassName("singleSitFifth")[x]);
        let sitSixth = this.$echarts.init(document.getElementsByClassName("singleSitSixth")[x]);
        let sitSeventh = this.$echarts.init(document.getElementsByClassName("singleSitSeventh")[x]);
        let sitEight = this.$echarts.init(document.getElementsByClassName("singleSitEigth")[x]);
        let sitNinth = this.$echarts.init(document.getElementsByClassName("singleSitNinth")[x]);
        for(var item = 0 ; item < formInt5.length; item++){
        // 分的不对 应该是按照从上一个到这一个
          //  const newSider = formFloat2.slice(content, formInt5[item]+1)
           const newSpeed = formFloat3.slice(content, formInt5[item]+1)
          //  sider.push(newSider)
           speed.push(newSpeed)
           timer.push(formDouble4[formInt5[item]] - formDouble4[demo])
           content = formInt5[item] + 1
           demo = formInt5[item]
         }
      // for(var i = 0 ;i < sider.length; i++){
      //   if(i % 2 === 0){
      //     leftSider.push(sider[i])
      //     status.push(i)
      //   }else{
      //     rightSider.push(sider[i])
      //   }
      // }
      // 对leftSider进行遍历
      // for(var i = 0 ; i < leftSider.length; i++){
      //   const result = leftSider[i].reduce((accmulator,item)=>{
      //     return accmulator+item;
      //   })
      //   avaLeftSider.push(result/(leftSider[i].length))    
      // }
      // for(var i =0 ; i < rightSider.length; i++){
      //    const result1 = rightSider[i].reduce((accmulator,item)=>{
      //     return accmulator+item;
      //   })
      //   avaRightSider.push(result1/(rightSider[i].length))
      // }
      for(var m = 0 ;m < timer.length ; m++){
        if(m % 2 === 0){
          leftTimer.push(timer[m])
        }else{
          rightTimer.push(timer[m])
        }
      }
      let Leftsum = 0
      let Rightsum = 0
      if(!Object.is(timer[0],NaN)){
        Leftsum = leftTimer.reduce((accumulator,item)=>{
         if(!Object.is(item,NaN)){
           accumulator+=item
        }
        return accumulator
      })
      Rightsum = rightTimer.reduce((accumulator,item)=>{
        if(!Object.is(item,NaN)){
           accumulator+=item
        }
        return accumulator
      })
      }
      
      const avaLeftTimer = Leftsum / (leftTimer.length)
      const avaRightTimer = Rightsum / (rightTimer.length)
      const avaTimer = (avaLeftTimer+avaRightTimer) / 2
      for(var t = 0 ; t < speed.length; t++){
          if(t % 2 === 0){
              leftSpeed.push(speed[t])
            }else{
              rightSpeed.push(speed[t])
            }
      }
      // const firstOption = {
      //   color: ["#80FFA5"],
      //   title: {
      //     text: "骨盆\n侧倾",
      //     top: "center",
      //     left: "left",
      //     // width: 30,
      //     // padding: 5,
      //     textStyle: {
      //       fontWeight: "normal",
      //       fontSize: 14,
      //       lineHeight: 14,
      //     },
      //   },
      //   grid: {
      //     top: "center",
      //     left: "15%",
      //     right: "10%",
      //     bottom: "4%",
      //     containLabel: true,
      //   },
      //   legend: {
      //     orient: "vertical",
      //     left: "right",
      //     top: "40%",
      //   },
      //   xAxis: {
      //     type: "category",
      //     name: "时间\n周期\n (s)",
      //     nameLocation: "end",
      //     // right: '-10',
      //     // bottom: '10',
      //     boundaryGap: false,
      //     // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      //   },
      //   yAxis: {
      //     type: "value",
      //     name: "位移(mm)",
      //     nameLocation: "end",
      //     nameGap: 30,
      //     nameTextStyle: {
      //       color: "#000",
      //       fontSize: 12,
      //     },
      //     axisLine: {
      //       show: true,
      //     },
      //   },
      //   series:[

      //   ]
      // };
      //  for(let m = 0 ; m < leftSider.length; m++ ){
      //   firstOption.series.push({
      //     name:'',
      //     type:'line',
      //     smooth: true,
      //     symbol:"none",
      //     animation:false,
      //     itemStyle: {
      //       normal: {
      //          color: 'blue',
      //          lineStyle:{
      //          width:0.5//设置线条粗细
      //          }
      //       }
      //     },
      //     data: leftSider[m]
      //   })
      // }
      // sitFirst.setOption(firstOption)
      // const secondOption = {
      //   title: {
      //     text: "骨盆\n侧倾",
      //     top: "center",
      //     textStyle: {
      //       fontWeight: "normal",
      //       fontSize: 14,
      //       lineHeight: 14,
      //     },
      //   },
      //   grid: {
      //     top: "center",
      //     left: "15%",
      //     right: "10%",
      //     bottom: "4%",
      //     containLabel: true,
      //   },
      //   legend: {
      //     orient: "vertical",
      //     left: "right",
      //     top: "40%",
      //   },
      //   xAxis: {
      //     type: "category",
      //     name: "时间\n周期\n (s)",
      //     nameLocation: "end",
      //     // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      //   },
      //   yAxis: {
      //     type: "value",
      //     name: "位移(mm)",
      //     nameLocation: "end",
      //     nameGap: 30,
      //     nameTextStyle: {
      //       color: "#000",
      //       fontSize: 12,
      //     },
      //     axisLine: {
      //       show: true,
      //     },
      //   },
      //   series:[
          
      //   ]
      // };
      // for(let m = 0 ; m < rightSider.length; m++ ){
      //   secondOption.series.push({
      //     name:'',
      //     type:'line',
      //     smooth: true,
      //     symbol:"none",
      //     animation:false,
      //     itemStyle: {
      //       normal: {
      //          color: 'red',
      //          lineStyle:{
      //          width:0.4//设置线条粗细
      //          }
      //       }
      //     },
      //     data: rightSider[m]
      //   })
      // }
      // 盆骨侧倾平均值
      // const thirdOption = {
      //   title: {
      //     text: "骨盆侧倾\n  平均值",
      //     top: "center",
      //     textStyle: {
      //       fontWeight: "normal",
      //       fontSize: 14,
      //       lineHeight: 14,
      //     },
      //   },
      //   grid: {
      //     top: "center",
      //     left: "15%",
      //     right: "10%",
      //     bottom: "4%",
      //     containLabel: true,
      //   },
      //   legend: {
      //     orient: "vertical",
      //     left: "right",
      //     top: "40%",
      //     data:[ 'Left','Right'],
      //     color:['red','blue'],
      //     icon:'rect',
      //     itemHeight: 2,
      //   },
      //   xAxis: {
      //     type: "category",
      //     name: "时间\n周期\n (s)",
      //     nameLocation: "end",
      //     // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      //   },
      //   yAxis: {
      //     type: "value",
      //     name: "位移(mm)",
      //     nameLocation: "end",
      //     nameGap: 30,
      //     nameTextStyle: {
      //       color: "#000",
      //       fontSize: 12,
      //     },
      //     axisLine: {
      //       show: true,
      //     },
      //   },
      //   series:[

      //   ]
      // };

      // thirdOption.series.push({
      //   name:'Left',
      //   type: 'line',
      //   smooth: true,
      //   symbol: "none",
      //   animation: false,
      //   itemStyle: {
      //       normal: {
      //          color: 'blue',
      //          lineStyle:{
      //          width:2//设置线条粗细
      //          }
      //       }
      //     },
      //   data: avaLeftSider
      // })
      // thirdOption.series.push({
      //   name:'Right',
      //   type: 'line',
      //   smooth: true,
      //   symbol: "none",
      //   animation: false,
      //   itemStyle: {
      //       normal: {
      //          color: 'red',
      //          lineStyle:{
      //          width:2//设置线条粗细
      //          }
      //       }
      //     },
      //   data: avaRightSider
      // })
      const forthOption = {
        color: ["red"],
        title: {
          text: "站-坐\n时 间",
          top: "center",
          left: "0",
          textStyle: {
            fontWeight: "normal",
            fontSize: 14,
            // lineHeight: 14,
          },
        },
        grid: {
          // top: "center",
          // left: "15%",
          // right: "10%",
          // bottom: "4%",
          containLabel: true,
        },
        
        xAxis: [
          {
            type: "category",
            name: "时间\n周期\n  (s)",
            boundaryGap: false,
            axisTick: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "时间(s)",
            nameocation: "end",
            min:0,
            max:10,
            axisLine: {
              show: true,
            },
          },
        ],
        series: [
          {
            type: "line",
            smooth: true,
            color: '#ccc',
            symbol: 'none',
            areaStyle: {
              opacity: 0.8,
              color: "#ccc",
            },
            animation:false,
            data: [6, 6, 5.94, 5.88, 5.94, 6, 6, 5.94, 6, 6],
          },
          {
            type: "line",
            symbol: 'none',
            smooth: true,
            color: '#ccc',
            areaStyle: {
              opacity: 0.8,
              color: "#fff",
            },
            animation:false,
            data: [4, 4, 3.94, 3.88, 3.94, 4, 4, 3.94, 4, 4],
          },
        ],
      };
      forthOption.series.push({
          type:'line',
          smooth: true,
          symbol:"none",
          animation: false,
          itemStyle: {
            normal: {
               color: 'blue',
               lineStyle:{
               width:2//设置线条粗细
               }
            }
          },
          data: leftTimer
      })
      const fifthOption = {
        title: {
          text: "坐-站\n时 间",
          top: "center",
          left: "0",
          textStyle: {
            fontWeight: "normal",
            fontSize: 14,
            lineHeight: 14,
          },
        },
        grid: {
          // top: "center",
          // left: "15%",
          // right: "10%",
          // bottom: "4%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            name: "时间\n周期\n  (s)",
            boundaryGap: false,
            axisTick: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "时间(s)",
            min: 0,
            max: 10,
            interval: 1,
            nameocation: "end",
            axisLine: {
              show: true,
            },
          },
        ],
        series: [
          {
            type: "line",
            color:'#ccc',
            symbol: 'none',
            smooth: true,
            lineStyle: {
              width: 0,
            },
            areaStyle: {
              opacity: 0.8,
              color: "#ccc",
            },
            animation:false,
            emphasis: {
              focus: "series",
            },
            data: [6, 6, 5.94, 5.88, 5.94, 6, 6, 5.94, 6, 6],
          },
          {
            type: "line",
            smooth: true,
            color:"#ccc",
            symbol: "none",
            lineStyle: {
              width: 0,
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: "#fff",
            },
            emphasis: {
              focus: "series",
            },
            animation:false,
            data: [4, 4, 3.94, 3.88, 3.94, 4, 4, 3.94, 4, 4],
          },
        ],
      };
      fifthOption.series.push({
          type:'line',
          smooth: true,
          symbol:"none",
          animation:false,
          itemStyle: {
            normal: {
               color: 'red',
               lineStyle:{
               width:2//设置线条粗细
               }
            }
          },
          data: rightTimer
      })
      // 平均 柱状图
      const sixthOption = {
        title: {
          text: "坐站时间\n 平均值",
          top: "center",
          left: "0",
          textStyle: {
            fontWeight: "normal",
            fontSize: 14,
            lineHeight: 14,
          },
        },

        grid: {
          // top: "center",
          left: "17%",
          // right: "10%",
          // bottom: "4%",
          containLabel: true,
        },
        legend: {
          // orient: "vertical",
          // left: "right",
          top: "5%",
          left: 'center',
          data:[ 'Left','Right','Average'],
          icon:'pin',
          itemHeight: 2,
        },
        xAxis: {
          type: "category",
          name: "时间\n周期\n  (s)",
          nameLocation: "end",
        },
        yAxis: {
          type: "value",
          name: "时间(s)",
          nameLocation: "end",
          nameGap: 30,
          nameTextStyle: {
            color: "#000",
            fontSize: 12,
          },
          axisLine: {
            show: true,
          },
        },
        series:[
        ]
      };
      sixthOption.series.push({
        name:'Left',
        type: 'bar',
        smooth: true,
        symbol: "none",
        animation:false,
        itemStyle: {
            normal: {
               color: 'red',
            }
          },
        data: [avaLeftTimer]
      })
      sixthOption.series.push({
        name:'Average',
        type: 'bar',
        smooth: true,
        symbol: "none",
        animation: false,
        itemStyle: {
            normal: {
               color: 'yellow',
            }
          },
        data: [avaTimer]
      })
      sixthOption.series.push({
        name:'Right',
        type: 'bar',
        smooth: true,
        symbol: "none",
        animation: false,
        itemStyle: {
            normal: {
               color: 'blue',
            }
          },
        data: [avaRightTimer]
      })
      // 速度图表
      const seventhOption = {
        title: {
          text: "站-坐\n速 度",
          top: "center",
          left: '0',
          textStyle: {
            fontWeight: "normal",
            fontSize: 14,
            lineHeight: 14,
          },
        },
        grid: {
          // top: "center",
          // left: "15%",
          // right: "10%",
          // bottom: "4%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            axisTick: {
              show: false,
            },
            name: "时间\n周期\n  (s)",
            nameLocation: "end",
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLine: {
              show: true,
            },
            name: "速度(mm/s)",
            nameLocation: "end",
          },
        ],
        series: [
          {
            type: "line",
            // stack: 'Total',
            smooth: true,
            animation:false,
            lineStyle: {
              width: 0,
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: "#ccc",
            },
            data: [
              89.79, 87.77, 85.76, 84.77, 84.8, 88.92, 98.13, 111.43, 135.96,
              173.99, 202.38, 217.71, 230.99, 239.18, 247.4, 247.4, 243.35,
              236.24, 225.06, 210.82, 172.13, 138.53, 100.86, 93.75, 91.74,
              90.76
            ],
          },
          {
            type: "line",
            smooth: true,
            animation:false,
            lineStyle: {
              width: 0,
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: "#fff",
            },
            data: [
              42.86, 41.85, 40.86, 38.85, 39.9, 45.04, 54.26, 65.51, 88, 118.07,
              150.44, 168.73, 182.01, 191.22, 197.38, 198.42, 196.41, 189.3,
              179.14, 160.92, 128.25, 93.63, 54.94, 45.79, 43.78, 44.84,
            ],
          },
        ],
      };
      for(let m = 0 ; m < rightSpeed.length; m++){
        seventhOption.series.push({
          type: 'line',
          smooth: true,
          symbol: "none",
          animation:false,
          itemStyle: {
            normal: {
               color: 'blue',
               lineStyle:{
               width:0.4//设置线条粗细
               }
            }
          },
          data: rightSpeed[m]
        })
      }
      const eighthOption = {
        title: {
          text: "坐-站\n速 度",
          top: "center",
          left: '0',
          textStyle: {
            fontWeight: "normal",
            fontSize: 14,
            lineHeight: 14,
          },
        },
        grid: {
          // top: "center",
          // left: "15%",
          // right: "10%",
          // bottom: "4%",
          containLabel: true,
        },
        legend: {
          orient: "vertical",
          left: "right",
          top: "40%",
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            axisTick: {
              show: false,
            },
            name: "时间\n周期\n  (s)",
            nameLocation: "end",
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLine: {
              show: true,
            },
            name: "速度(mm/s)",
            nameLocation: "end",
          },
        ],
        series: [
          {
            type: "line",
            // stack: 'Total',
            animation:false,
            smooth: true,
            lineStyle: {
              width: 0,
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: "#ccc",
            },
            data: [
              89.79, 87.77, 85.76, 84.77, 84.8, 88.92, 98.13, 111.43, 135.96,
              173.99, 202.38, 217.71, 230.99, 239.18, 247.4, 247.4, 243.35,
              236.24, 225.06, 210.82, 172.13, 138.53, 100.86, 93.75, 91.74,
              90.76,
            ],
          },
          {
            type: "line",
            smooth: true,
            animation:false,
            lineStyle: {
              width: 0,
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: "#fff",
            },
            data: [
              42.86, 41.85, 40.86, 38.85, 39.9, 45.04, 54.26, 65.51, 88, 118.07,
              150.44, 168.73, 182.01, 191.22, 197.38, 198.42, 196.41, 189.3,
              179.14, 160.92, 128.25, 93.63, 54.94, 45.79, 43.78, 44.84,
            ],
          },
        ],
      };
      for(let m = 0 ; m < leftSpeed.length; m++){
        for(let i = 0 ; i < leftSpeed[m].length; i ++){
          leftSpeed[m][i] = -leftSpeed[m][i]
        }
        eighthOption.series.push({
          type: 'line',
          smooth: true,
          symbol: "none",
          animation: false,
          itemStyle: {
            normal: {
               color: 'red',
               lineStyle:{
               width:0.4//设置线条粗细
               }
            }
          },
        data: leftSpeed[m]
      })
      }
      for(var i = 0 ; i < leftSpeed.length; i++){
        const result = leftSpeed[i].reduce((accmulator,item)=>{
          return accmulator+item;
        })
        avaLeftSpeed.push(result/(leftSpeed[i].length))  
      }
      for(var i = 0; i < rightSpeed.length; i++){
        const result1 = rightSpeed[i].reduce((accmulator,item)=>{
          return accmulator+item;
        })
        avaRightSpeed.push(result1/(rightSpeed[i].length))
      }
      const nighthOption = {
        title: {
          text: "速   度\n平均值",
          top: "center",
          left: '0',
          textStyle: {
            fontWeight: "normal",
            fontSize: 14,
            lineHeight: 14,
          },
        },
        legend: {
          // orient: "vertical",
          left: "center",
          top: "5%",
          data:['Left','Right'],
          icon:'pin',
          itemHeight: 2,
        },
        grid: {
          // top: "center",
          left: "17%",
          // right: "10%",
          // bottom: "4%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          name: "时间\n周期\n  (s)",
          nameLocation: "end",
          // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        },
        yAxis: {
          type: "value",
          name: "速度(mm/s)",
          nameLocation: "end",
          nameGap: 30,
          nameTextStyle: {
            color: "#000",
            fontSize: 12,
          },
          axisLine: {
            show: true,
          },
        },
        series:[

        ]
      };
      nighthOption.series.push({
        name:'Left',
        type:'line',
        symbol:'none',
        smooth: true,
        animation: false,
        itemStyle: {
            normal: {
               color: 'blue',
               lineStyle:{
               width:0.4//设置线条粗细
               }
            }
          },
        data: avaRightSpeed
      })
      nighthOption.series.push({
        name:'Right',
        type:'line',
        symbol:'none',
        smooth: true,
        animation:false,
        itemStyle: {
            normal: {
               color: 'red',
               lineStyle:{
               width:0.4//设置线条粗细
               }
            }
          },
        data: avaLeftSpeed
      })
      sitForth.setOption(forthOption)
      sitFifth.setOption(fifthOption)
      sitSixth.setOption(sixthOption)
      sitSeventh.setOption(seventhOption)
      sitEight.setOption(eighthOption)
      sitNinth.setOption(nighthOption)
      }else{
           let sitForth = document.getElementsByClassName("single_sit_forth")[x];
           let sitFifth = document.getElementsByClassName("single_sit_fifth")[x];
           let sitSixth = document.getElementsByClassName("single_sit_sixth")[x];
           let sitSeventh = document.getElementsByClassName("single_sit_seventh")[x];
           let sitEight = document.getElementsByClassName("single_sit_eigth")[x];
           let sitNinth = document.getElementsByClassName("single_sit_ninth")[x];
           sitForth !== undefined ? sitForth.style.display = "none" : '';
           sitFifth !== undefined ? sitFifth.style.display = "none" : 
           sitSixth !== undefined ?sitSixth.style.display = "none" : '';
           sitSeventh !== undefined ?sitSeventh.style.display = "none" : '';
           sitEight !== undefined ?sitEight.style.display = "none": '';
           sitNinth !== undefined ?sitNinth.style.display = "none": '';
        }
        }
    },
    // 坐站
    initSitAndStandEcharts(data){
      console.log('xxx', this.sitExsist);
      for(var x = 0 ; x < data.length ; x++){
        if(data[x].formFloat1 !== null && data[x].formInt5 !== null && data[x].formInt5.length > 1){
          let sider=[],speed=[],timer=[],leftSider=[],rightSider=[],leftSpeed=[],rightSpeed=[],
            leftTimer=[],rightTimer=[],status=[],avaSider=[],avaLeftSider=[],avaRightSider=[],avaLeftSpeed = [],avaRightSpeed = []
        const {formDouble4,formFloat2,formFloat3,formInt5} = data[x]
        let content = 0
        let demo = 0

        let sitForth = this.$echarts.init(document.getElementsByClassName("sit_forth")[x]);
        let sitFifth = this.$echarts.init(document.getElementsByClassName("sit_fifth")[x]);
        let sitSixth = this.$echarts.init(document.getElementsByClassName("sit_sixth")[x]);
        let sitSeventh = this.$echarts.init(document.getElementsByClassName("sit_seventh")[x]);
        let sitEight = this.$echarts.init(document.getElementsByClassName("sit_eigth")[x]);
        let sitNinth = this.$echarts.init(document.getElementsByClassName("sit_ninth")[x]);
        for(var item = 0 ; item < formInt5.length; item++){
        // 分的不对 应该是按照从上一个到这一个
           const newSider = formFloat2.slice(content, formInt5[item]+1)
           const newSpeed = formFloat3.slice(content, formInt5[item]+1)
           sider.push(newSider)
           speed.push(newSpeed)
           timer.push(formDouble4[formInt5[item]] - formDouble4[demo])
           content = formInt5[item] + 1
           demo = formInt5[item]
         }
      for(var i = 0 ;i < sider.length; i++){
        if(i % 2 === 0){
          leftSider.push(sider[i])
          status.push(i)
        }else{
          rightSider.push(sider[i])
        }
      }
      // 对leftSider进行遍历
      for(var i = 0 ; i < leftSider.length; i++){
        const result = leftSider[i].reduce((accmulator,item)=>{
          return accmulator+item;
        })
        avaLeftSider.push(result/(leftSider[i].length))    
      }
      for(var i =0 ; i < rightSider.length; i++){
         const result1 = rightSider[i].reduce((accmulator,item)=>{
          return accmulator+item;
        })
        avaRightSider.push(result1/(rightSider[i].length))
      }
      for(var m = 0 ;m < timer.length ; m++){
        if(m % 2 === 0){
          leftTimer.push(timer[m])
        }else{
          rightTimer.push(timer[m])
        }
      }
      let Leftsum = 0
      let Rightsum = 0
      if(!Object.is(timer[0],NaN)){
        Leftsum = leftTimer.reduce((accumulator,item)=>{
         if(!Object.is(item,NaN)){
           accumulator+=item
        }
        return accumulator
      })
      Rightsum = rightTimer.reduce((accumulator,item)=>{
        if(!Object.is(item,NaN)){
           accumulator+=item
        }
        return accumulator
      })
      }
      
      const avaLeftTimer = Leftsum / (leftTimer.length)
      const avaRightTimer = Rightsum / (rightTimer.length)
      const avaTimer = (avaLeftTimer+avaRightTimer) / 2
      for(var t = 0 ; t < speed.length; t++){
          if(t % 2 === 0){
              leftSpeed.push(speed[t])
            }else{
              rightSpeed.push(speed[t])
            }
      }

     
      const firstOption = {
        color: ["#80FFA5"],
        title: {
          text: "骨盆\n侧倾",
          top: "center",
          left: "left",
          // width: 30,
          // padding: 5,
          textStyle: {
            fontWeight: "normal",
            fontSize: 14,
            lineHeight: 14,
          },
        },
        grid: {
          top: "center",
          left: "15%",
          right: "10%",
          bottom: "4%",
          containLabel: true,
        },
        legend: {
          orient: "vertical",
          left: "right",
          top: "40%",
        },
        xAxis: {
          type: "category",
          name: "时间\n周期\n (s)",
          nameLocation: "end",
          // right: '-10',
          // bottom: '10',
          boundaryGap: false,
          // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        },
        yAxis: {
          type: "value",
          name: "位移(mm)",
          nameLocation: "end",
          nameGap: 30,
          nameTextStyle: {
            color: "#000",
            fontSize: 12,
          },
          axisLine: {
            show: true,
          },
        },
        series:[

        ]
      };
       for(let m = 0 ; m < leftSider.length; m++ ){
        firstOption.series.push({
          name:'',
          type:'line',
          smooth: true,
          symbol:"none",
          animation:false,
          itemStyle: {
            normal: {
               color: 'blue',
               lineStyle:{
               width:0.5//设置线条粗细
               }
            }
          },
          data: leftSider[m]
        })
      }
      // sitFirst.setOption(firstOption)
      const secondOption = {
        title: {
          text: "骨盆\n侧倾",
          top: "center",
          textStyle: {
            fontWeight: "normal",
            fontSize: 14,
            lineHeight: 14,
          },
        },
        grid: {
          top: "center",
          left: "15%",
          right: "10%",
          bottom: "4%",
          containLabel: true,
        },
        legend: {
          orient: "vertical",
          left: "right",
          top: "40%",
        },
        xAxis: {
          type: "category",
          name: "时间\n周期\n (s)",
          nameLocation: "end",
          // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        },
        yAxis: {
          type: "value",
          name: "位移(mm)",
          nameLocation: "end",
          nameGap: 30,
          nameTextStyle: {
            color: "#000",
            fontSize: 12,
          },
          axisLine: {
            show: true,
          },
        },
        series:[
          
        ]
      };
      for(let m = 0 ; m < rightSider.length; m++ ){
        secondOption.series.push({
          name:'',
          type:'line',
          smooth: true,
          symbol:"none",
          animation:false,
          itemStyle: {
            normal: {
               color: 'red',
               lineStyle:{
               width:0.4//设置线条粗细
               }
            }
          },
          data: rightSider[m]
        })
      }
      // 盆骨侧倾平均值
      const thirdOption = {
        title: {
          text: "骨盆侧倾\n  平均值",
          top: "center",
          textStyle: {
            fontWeight: "normal",
            fontSize: 14,
            lineHeight: 14,
          },
        },
        grid: {
          top: "center",
          left: "15%",
          right: "10%",
          bottom: "4%",
          containLabel: true,
        },
        legend: {
          orient: "vertical",
          left: "right",
          top: "40%",
          data:[ 'Left','Right'],
          color:['red','blue'],
          icon:'rect',
          itemHeight: 2,
        },
        xAxis: {
          type: "category",
          name: "时间\n周期\n (s)",
          nameLocation: "end",
          // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        },
        yAxis: {
          type: "value",
          name: "位移(mm)",
          nameLocation: "end",
          nameGap: 30,
          nameTextStyle: {
            color: "#000",
            fontSize: 12,
          },
          axisLine: {
            show: true,
          },
        },
        series:[

        ]
      };

      thirdOption.series.push({
        name:'Left',
        type: 'line',
        smooth: true,
        symbol: "none",
        animation: false,
        itemStyle: {
            normal: {
               color: 'blue',
               lineStyle:{
               width:2//设置线条粗细
               }
            }
          },
        data: avaLeftSider
      })
      thirdOption.series.push({
        name:'Right',
        type: 'line',
        smooth: true,
        symbol: "none",
        animation: false,
        itemStyle: {
            normal: {
               color: 'red',
               lineStyle:{
               width:2//设置线条粗细
               }
            }
          },
        data: avaRightSider
      })
      const forthOption = {
        color: ["red"],
        title: {
          text: "站-坐\n时 间",
          top: "center",
          left: "0",
          textStyle: {
            fontWeight: "normal",
            fontSize: 14,
            // lineHeight: 14,
          },
        },
        grid: {
          // top: "center",
          // left: "15%",
          // right: "10%",
          // bottom: "4%",
          containLabel: true,
        },
        
        xAxis: [
          {
            type: "category",
            name: "时间\n周期\n  (s)",
            boundaryGap: false,
            axisTick: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "时间(s)",
            nameocation: "end",
            min:0,
            max:10
            ,
            axisLine: {
              show: true,
            },
          },
        ],
        series: [
          {
            type: "line",
            smooth: true,
            color: '#ccc',
            symbol: 'none',
            areaStyle: {
              opacity: 0.8,
              color: "#ccc",
            },
            animation:false,
            data: [6, 6, 5.94, 5.88, 5.94, 6, 6, 5.94, 6, 6],
          },
          {
            type: "line",
            symbol: 'none',
            smooth: true,
            color: '#ccc',
            areaStyle: {
              opacity: 0.8,
              color: "#fff",
            },
            animation:false,
            data: [4, 4, 3.94, 3.88, 3.94, 4, 4, 3.94, 4, 4],
          },
        ],
      };
      forthOption.series.push({
          type:'line',
          smooth: true,
          symbol:"none",
          animation: false,
          itemStyle: {
            normal: {
               color: 'blue',
               lineStyle:{
               width:2//设置线条粗细
               }
            }
          },
          data: leftTimer
      })
      const fifthOption = {
        title: {
          text: "坐-站\n时 间",
          top: "center",
          left: "0",
          textStyle: {
            fontWeight: "normal",
            fontSize: 14,
            lineHeight: 14,
          },
        },
        grid: {
          // top: "center",
          // left: "15%",
          // right: "10%",
          // bottom: "4%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            name: "时间\n周期\n  (s)",
            boundaryGap: false,
            axisTick: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "时间(s)",
            min: 0,
            max: 10,
            interval: 1,
            nameocation: "end",
            axisLine: {
              show: true,
            },
          },
        ],
        series: [
          {
            type: "line",
            color:'#ccc',
            symbol: 'none',
            smooth: true,
            lineStyle: {
              width: 0,
            },
            areaStyle: {
              opacity: 0.8,
              color: "#ccc",
            },
            animation:false,
            emphasis: {
              focus: "series",
            },
            data: [6, 6, 5.94, 5.88, 5.94, 6, 6, 5.94, 6, 6],
          },
          {
            type: "line",
            smooth: true,
            color:"#ccc",
            symbol: "none",
            lineStyle: {
              width: 0,
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: "#fff",
            },
            emphasis: {
              focus: "series",
            },
            animation:false,
            data: [4, 4, 3.94, 3.88, 3.94, 4, 4, 3.94, 4, 4],
          },
        ],
      };
      fifthOption.series.push({
          type:'line',
          smooth: true,
          symbol:"none",
          animation:false,
          itemStyle: {
            normal: {
               color: 'red',
               lineStyle:{
               width:2//设置线条粗细
               }
            }
          },
          data: rightTimer
      })
      // 平均 柱状图
      const sixthOption = {
        title: {
          text: "坐站时间\n 平均值",
          top: "center",
          left: "0",
          textStyle: {
            fontWeight: "normal",
            fontSize: 14,
            lineHeight: 14,
          },
        },

        grid: {
          // top: "center",
          left: "17%",
          // right: "10%",
          // bottom: "4%",
          containLabel: true,
        },
        legend: {
          // orient: "vertical",
          // left: "right",
          top: "5%",
          left: 'center',
          data:[ 'Left','Right','Average'],
          icon:'pin',
          itemHeight: 2,
        },
        xAxis: {
          type: "category",
          name: "时间\n周期\n  (s)",
          nameLocation: "end",
        },
        yAxis: {
          type: "value",
          name: "时间(s)",
          nameLocation: "end",
          nameGap: 30,
          nameTextStyle: {
            color: "#000",
            fontSize: 12,
          },
          axisLine: {
            show: true,
          },
        },
        series:[
        ]
      };
      sixthOption.series.push({
        name:'Left',
        type: 'bar',
        smooth: true,
        symbol: "none",
        animation:false,
        itemStyle: {
            normal: {
               color: 'red',
            }
          },
        data: [avaLeftTimer]
      })
      sixthOption.series.push({
        name:'Average',
        type: 'bar',
        smooth: true,
        symbol: "none",
        animation: false,
        itemStyle: {
            normal: {
               color: 'yellow',
            }
          },
        data: [avaTimer]
      })
      sixthOption.series.push({
        name:'Right',
        type: 'bar',
        smooth: true,
        symbol: "none",
        animation: false,
        itemStyle: {
            normal: {
               color: 'blue',
            }
          },
        data: [avaRightTimer]
      })
      // 速度图表
      const seventhOption = {
        title: {
          text: "站-坐\n速 度",
          top: "center",
          left: '0',
          textStyle: {
            fontWeight: "normal",
            fontSize: 14,
            lineHeight: 14,
          },
        },
        grid: {
          // top: "center",
          // left: "15%",
          // right: "10%",
          // bottom: "4%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            axisTick: {
              show: false,
            },
            name: "时间\n周期\n  (s)",
            nameLocation: "end",
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLine: {
              show: true,
            },
            name: "速度(mm/s)",
            nameLocation: "end",
          },
        ],
        series: [
          {
            type: "line",
            // stack: 'Total',
            smooth: true,
            animation:false,
            lineStyle: {
              width: 0,
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: "#ccc",
            },
            data: [
              89.79, 87.77, 85.76, 84.77, 84.8, 88.92, 98.13, 111.43, 135.96,
              173.99, 202.38, 217.71, 230.99, 239.18, 247.4, 247.4, 243.35,
              236.24, 225.06, 210.82, 172.13, 138.53, 100.86, 93.75, 91.74,
              90.76
            ],
          },
          {
            type: "line",
            smooth: true,
            animation:false,
            lineStyle: {
              width: 0,
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: "#fff",
            },
            data: [
              42.86, 41.85, 40.86, 38.85, 39.9, 45.04, 54.26, 65.51, 88, 118.07,
              150.44, 168.73, 182.01, 191.22, 197.38, 198.42, 196.41, 189.3,
              179.14, 160.92, 128.25, 93.63, 54.94, 45.79, 43.78, 44.84,
            ],
          },
        ],
      };
      for(let m = 0 ; m < rightSpeed.length; m++){
        seventhOption.series.push({
          type: 'line',
          smooth: true,
          symbol: "none",
          animation:false,
          itemStyle: {
            normal: {
               color: 'blue',
               lineStyle:{
               width:0.4//设置线条粗细
               }
            }
          },
          data: rightSpeed[m]
        })
      }
      const eighthOption = {
        title: {
          text: "坐-站\n速 度",
          top: "center",
          left: '0',
          textStyle: {
            fontWeight: "normal",
            fontSize: 14,
            lineHeight: 14,
          },
        },
        grid: {
          // top: "center",
          // left: "15%",
          // right: "10%",
          // bottom: "4%",
          containLabel: true,
        },
        legend: {
          orient: "vertical",
          left: "right",
          top: "40%",
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            axisTick: {
              show: false,
            },
            name: "时间\n周期\n  (s)",
            nameLocation: "end",
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLine: {
              show: true,
            },
            name: "速度(mm/s)",
            nameLocation: "end",
          },
        ],
        series: [
          {
            type: "line",
            // stack: 'Total',
            animation:false,
            smooth: true,
            lineStyle: {
              width: 0,
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: "#ccc",
            },
            data: [
              89.79, 87.77, 85.76, 84.77, 84.8, 88.92, 98.13, 111.43, 135.96,
              173.99, 202.38, 217.71, 230.99, 239.18, 247.4, 247.4, 243.35,
              236.24, 225.06, 210.82, 172.13, 138.53, 100.86, 93.75, 91.74,
              90.76,
            ],
          },
          {
            type: "line",
            smooth: true,
            animation:false,
            lineStyle: {
              width: 0,
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: "#fff",
            },
            data: [
              42.86, 41.85, 40.86, 38.85, 39.9, 45.04, 54.26, 65.51, 88, 118.07,
              150.44, 168.73, 182.01, 191.22, 197.38, 198.42, 196.41, 189.3,
              179.14, 160.92, 128.25, 93.63, 54.94, 45.79, 43.78, 44.84,
            ],
          },
        ],
      };
      for(let m = 0 ; m < leftSpeed.length; m++){
        for(let i = 0 ; i < leftSpeed[m].length; i ++){
          leftSpeed[m][i] = -leftSpeed[m][i]
        }
        eighthOption.series.push({
          type: 'line',
          smooth: true,
          symbol: "none",
          animation: false,
          itemStyle: {
            normal: {
               color: 'red',
               lineStyle:{
               width:0.4//设置线条粗细
               }
            }
          },
        data: leftSpeed[m]
      })
      }
      for(var i = 0 ; i < leftSpeed.length; i++){
        const result = leftSpeed[i].reduce((accmulator,item)=>{
          return accmulator+item;
        })
        avaLeftSpeed.push(result/(leftSpeed[i].length))  
      }
      for(var i = 0; i < rightSpeed.length; i++){
        const result1 = rightSpeed[i].reduce((accmulator,item)=>{
          return accmulator+item;
        })
        avaRightSpeed.push(result1/(rightSpeed[i].length))
      }
      const nighthOption = {
        title: {
          text: "速   度\n平均值",
          top: "center",
          left: '0',
          textStyle: {
            fontWeight: "normal",
            fontSize: 14,
            lineHeight: 14,
          },
        },
        legend: {
          // orient: "vertical",
          left: "center",
          top: "5%",
          data:['Left','Right'],
          icon:'pin',
          itemHeight: 2,
        },
        grid: {
          // top: "center",
          left: "17%",
          // right: "10%",
          // bottom: "4%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          name: "时间\n周期\n  (s)",
          nameLocation: "end",
          // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        },
        yAxis: {
          type: "value",
          name: "速度(mm/s)",
          nameLocation: "end",
          nameGap: 30,
          nameTextStyle: {
            color: "#000",
            fontSize: 12,
          },
          axisLine: {
            show: true,
          },
        },
        series:[

        ]
      };
      nighthOption.series.push({
        name:'Left',
        type:'line',
        symbol:'none',
        smooth: true,
        animation: false,
        itemStyle: {
            normal: {
               color: 'blue',
               lineStyle:{
               width:0.4//设置线条粗细
               }
            }
          },
        data: avaRightSpeed
      })
      nighthOption.series.push({
        name:'Right',
        type:'line',
        symbol:'none',
        smooth: true,
        animation:false,
        itemStyle: {
            normal: {
               color: 'red',
               lineStyle:{
               width:0.4//设置线条粗细
               }
            }
          },
        data: avaLeftSpeed
      })
      sitForth.setOption(forthOption)
      sitFifth.setOption(fifthOption)
      sitSixth.setOption(sixthOption)
      sitSeventh.setOption(seventhOption)
      sitEight.setOption(eighthOption)
      sitNinth.setOption(nighthOption)
      
        }else{
           let sitForth = document.getElementsByClassName("sit_forth")[x];
           let sitFifth = document.getElementsByClassName("sit_fifth")[x];
           let sitSixth = document.getElementsByClassName("sit_sixth")[x];
           let sitSeventh = document.getElementsByClassName("sit_seventh")[x];
           let sitEight = document.getElementsByClassName("sit_eigth")[x];
           let sitNinth = document.getElementsByClassName("sit_ninth")[x];
           sitForth !== undefined ? sitForth.style.display = "none" : '';
           sitFifth !== undefined ? sitFifth.style.display = "none" : 
           sitSixth !== undefined ?sitSixth.style.display = "none" : '';
           sitSeventh !== undefined ?sitSeventh.style.display = "none" : '';
           sitEight !== undefined ?sitEight.style.display = "none": '';
           sitNinth !== undefined ?sitNinth.style.display = "none": '';
        }
        }
    },
    // 游戏表格
    initGameEchrts(data){
      // const self = this;
      const games = []
      for(let i=0 ; i < data.length ; i++){
        
        if(data[i].formFloat1 !== null){
          var option = {
          tooltip: {
           trigger: 'axis',
          },
          legend: {
           orient: 'verticalAlign',
           right: '0',
           top: 'center',
           data: ['前后', '左右'],
           icon: 'rect',
           itemHeight: 4,
         },
         grid: {
          left: '3%',
          right: '12%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          // data: ['0', '0.2', '0.4', '0.6', '0.8', '1'],
          name: '时间(s)'
        },
        yAxis: {
          type: 'value',
          name: '位移(mm)',
          data: [-40, -20, 0, 20, 40],
        },
        series:[
          {
            name:'前后',
            data: data[i].formFloat1,
            type:'line',
            smooth: true,
            animation:false,
            symbol:"none",
          },{
            name:'左右',
            data: data[i].formFloat2,
            type:'line',
            smooth:true,
            animation:false,
            symbol:"none"
          }
        ]
        };
        // console.log(document.getElementsByClassName('game'))
        games[i] = this.$echarts.init(document.getElementsByClassName('game')[i]);
        games[i].setOption(option)
        }
        // else{
        //   console.log(document.getElementsByClassName('game')[0]);
        //   games[i] = document.getElementsByClassName('game')[i]
        //   games[i].style.display = "none"
        // }
      }
    },
    toolNumber(num_str) {
        num_str = num_str.toString();
        if (num_str.indexOf("+") != -1) {
          num_str = num_str.replace("+", "");
        }
        if (num_str.indexOf("E") != -1 || num_str.indexOf("e") != -1) {
          var resValue = "",
            power = "",
            result = null,
            dotIndex = 0,
            resArr = [],
            sym = "";
          var numStr = num_str.toString();
          if (numStr[0] == "-") {
            // 如果为负数，转成正数处理，先去掉‘-’号，并保存‘-’.
            numStr = numStr.substr(1);
            sym = "-";
          }
          if (numStr.indexOf("E") != -1 || numStr.indexOf("e") != -1) {
            var regExp = new RegExp(
              "^(((\\d+.?\\d+)|(\\d+))[Ee]{1}((-(\\d+))|(\\d+)))$",
              "ig"
            );
            result = regExp.exec(numStr);
            if (result != null) {
              resValue = result[2];
              power = result[5];
              result = null;
            }
            if (!resValue && !power) {
              return false;
            }
            dotIndex = resValue.indexOf(".") == -1 ? 0 : resValue.indexOf(".");
            resValue = resValue.replace(".", "");
            resArr = resValue.split("");
            if (Number(power) >= 0) {
              var subres = resValue.substr(dotIndex);
              power = Number(power);
              //幂数大于小数点后面的数字位数时，后面加0
              for (var i = 0; i <= power - subres.length; i++) {
                resArr.push("0");
              }
              if (power - subres.length < 0) {
                resArr.splice(dotIndex + power, 0, ".");
              }
            } else {
              power = power.replace("-", "");
              power = Number(power);
              //幂数大于等于 小数点的index位置, 前面加0
              for (var i = 0; i < power - dotIndex; i++) {
                resArr.unshift("0");
              }
              var n = power - dotIndex >= 0 ? 1 : -(power - dotIndex);
              resArr.splice(n, 0, ".");
            }
          }
          resValue = resArr.join("");

          return sym + resValue;
        } else {
          return num_str;
        }
    },
    // 对象克隆
    cloneObj(oldClone, newClone){
      for(var item in oldClone){
        newClone[item] = oldClone[item]
      }
    },
    getQueryVariable(key){
       let query = window.location.search.substring(1);
       let vars = query.split("&");
       // return vars;
       var value = '';
       for (let i=0;i<vars.length;i++) {
          let pair = vars[i].split("=");
          if(pair[0] == key)
          {
            value = pair[1]
          }
       }
       return value;
       // console.log('vars', vars)
       },
    getAge(now,birth){
      // var birthDayTime = new Date(birth).getTime();
      // var nowTime = new Date(now).getTime()
      // return Math.ceil((nowTime - birthDayTime)/31536000000)
      let yearMonth = '';
      let Year = '';
      if(birth !== undefined) {
        yearMonth = birth.split(' ')[0]
        Year = yearMonth.split('-')[0]
      }
      const cal = new Date(now);
      const yearNow = cal.getFullYear();
      const yearMinus = yearNow - Year;
      return yearMinus;
    }
},
    
};
</script>

<style scoped>
.report-container {
  width: 80%;
  height: 100%;
  margin: 0 auto;
  justify-content: center;
  /* background-color: beige; */
}
.report-demo {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  background-color: #23223a;
  display: flex;
  align-items: center;
  justify-content: center;
}
.report-demo .img {
  text-align: center;
}
.report-demo img {
  text-align: center;
  width: 80%;
  height: 80%;
  z-index: 1;
  position: relative;
}
.report-demo .text {  
  color: #8ca0f0;
  font-size: 50px;
  z-index: 2;
  position: absolute;
  text-align: center;
  font-weight: 600;
}
.report-container .title-content {
  display: flex;
  flex-direction: column;
  height: 300px;
  justify-content: space-around;
  align-items: center;
}
.report-container .title-content .title {
  font-size: 48px;
  height: 48px;
  letter-spacing: 0px;
  color: #000;
}
.report-container .title-content .side-title {
  width: 340px;
  height: 24px;
  line-height: 24px;
  font-size: 24px;
  font-weight: normal;
  color: #000;
  font-family: "Microsoft YaHei" !important;
  justify-content: center;
}
.report-container .main-content {
  display: flex;
  flex-direction: column;
}
.report-container .main-content .basic-info {
  margin: 0 auto;
  width: 90%;
  margin-bottom: 40px;
}
.report-container .main-content .basic-info .content-title {
  display: flex;
}
.report-container .main-content .basic-info .content-title div {
  width: 85%;
  border-bottom: 3px solid #2f2e41;
}
.report-container .main-content .basic-info .content-title span {
  width: 15%;
  font-size: 26px;
  line-height: 26px;
  color: #000;
  padding: 8px 0;
  text-align: center;
  background-color: #ddd;
  border: 1px solid #2f2e41;
}
.report-container .main-content .basic-info .info-table {
  width: 90%;
  margin: 0 auto;
}
.report-container .main-content .basic-info .info-table tr {
  height: 49px;
  line-height: 49px;
  font-size: 20px;
  font-family: MicrosoftYaHei;
}
.report-container .main-content .basic-info .info-table td {
  width: 33%;
  height: 21px;
  line-height: 49px;
  letter-spacing: 3px;
  font-weight: normal;
}
/* walk */
.walk-info {
  font-size: 20px;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 40px;
}
.walk-info .content-title {
  display: flex;
}
.walk-info .content-title div {
  width: 85%;
  border-bottom: 3px solid #2f2e41;
}
.walk-info .content-title span {
  width: 15%;
  font-size: 26px;
  line-height: 26px;
  color: #000;
  padding: 8px 0;
  text-align: center;
  background-color: #ddd;
  border: 1px solid #2f2e41;
}
.walk-info .tips-info {
  display: flex;
  width: 100%;
  justify-content: center;
}
.walk-info .tips-info img {
  margin-top: 10px;
}
.walk-info .walk—table {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.walk-info .walk—table .table-content {
  width: 90%;
  border-collapse: collapse;
  margin-top: 10px;
}
.walk-info .walk—table .table-content .bg{
  background-color: #FBF6EF;
}
.walk-info .walk—table .table-content tr {
  width: 100%;
  line-height: 40px;
}
.walk-info .walk—table .table-content tr th {
  height: 21px;
  font-size: 20px;
  font-weight: normal;
  line-height: 49px;
  letter-spacing: 3px;
  color: #000;
  font-size: 24px;
  border-bottom: 1px solid #000;
}
.walk-info .walk—table .table-content tr th:first-child {
  font-size: 20px;
}
.walk-info .walk—table .table-content tr td {
  width: 47px;
  height: 60px;
  font-size: 20px;
  font-weight: normal;
  letter-spacing: 3px;
  color: #a4a4a4;
  margin: 0 10px;
  text-align: center;
}
.walk-info .walk—table .table-content .td-item{
  text-decoration: underline;
}
.walk-info .walk—table .table-content tr td:first-child {
  width: 120px;
}
.walk-info .walk—table .table-content tr td:last-child {
  width: 107px;
}
.walk-info .walk—table .table-content tr:first-child {
  width: 100%;
  border-bottom: 1px solid #000;
}
.walk-info .walk—table .closeBtn {
  width: 90%;
  height: 76px;
  background-color: #dedede;
  text-align: center;
}
.walk-info .walk—table .closeBtn span {
  height: 24px;
  line-height: 49px;
  font-size: 24px;
  letter-spacing: 3px;
}
.walk-info .walk—table .closeBtn img {
  margin-left: 5px;
}
.walk-info .walk—table .result-table {
  margin-top: 20px;
  width: 90%;
}
.walk-info .walk—table .result-table table {
  width: 100%;
}
.walk-info .walk—table .result-table table tr {
  height: 49px;
}
.walk-info .walk—table .result-table table tr td {
  width: 33%;
  height: 21px;
  font-size: 20px;
  font-weight: normal;
  letter-spacing: 3px;
  line-height: 49px;
}
.walk-info .walk—table .result-table table tr:first-child td {
  border-top: 1px dashed #000;
}
.walk-info .walk—table .result-table table tr:last-child {
  border-bottom: 1px dashed #000;
}
.walk-info .walk—table .table-info {
  font-size: 20px;
  font-weight: 100;
  height: 100%;
  width: 90%;
  background-color: transparent;
  margin-top: 17px;
}
.walk-info .walk—table .picture-content {
  display: flex;
  width: 100%;
  margin: 0 auto;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  /* margin: 10px; */
}
.walk-info .walk—table .picture-content div {
  flex: 1;
  width: calc(100% / 3);
  min-width: calc(100% / 3);
  max-width: calc(100% / 3);
  /* width: 33%; */
  /* border: 1px solid #ddd; */
}
.walk-info .walk—table .picture-content div :nth-child(3n){
  margin-right: 0;
}
.walk-info .walk—table img {
  margin-top: 50px;
}
.walk-info .picture-content {
  display: flex;
  justify-content: space-around;
}
.sitAndStand {
  margin: 0 auto;
  width: 90%;
  margin-bottom: 40px;
}
.sitAndStand .content-title {
  display: flex;
}
.sitAndStand .content-title div {
  width: 85%;
  border-bottom: 3px solid #2f2e41;
}
.sitAndStand .content-title span {
  width: 15%;
  font-size: 26px;
  line-height: 26px;
  color: #000;
  padding: 8px 0;
  text-align: center;
  background-color: #ddd;
  border: 1px solid #2f2e41;
}
.sitAndStand .container {
  display: flex;
  width: 100%;
  justify-content: center;
}
.sitAndStand .container img {
  margin-top: 10px;
}
.sitAndStand .sit-table {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.sitAndStand .sit-table .table-content {
  width: 90%;
  border-collapse: collapse;
  margin-top: 10px;
}
.sitAndStand .sit-table .table-content .bg{
  background-color: #FBF6EF;
}

.sitAndStand .sit-table .table-content tr {
  width: 100%;
  line-height: 40px;
}
.sitAndStand .sit-table .table-content tr th {
  height: 21px;
  font-size: 20px;
  font-weight: normal;
  line-height: 49px;
  letter-spacing: 3px;
  color: #000;
  font-size: 24px;
  border-bottom: 1px solid #000;
}
.sitAndStand .sit-table .table-content tr th:first-child {
  font-size: 20px;
}
.sitAndStand .sit-table .table-content tr td {
  width: 47px;
  height: 60px;
  font-size: 20px;
  font-weight: normal;
  letter-spacing: 3px;
  color: #a4a4a4;
  margin: 0 10px;
  text-align: center;
}
.sitAndStand .sit-table .table-content tr td:first-child {
  width: 120px;
}
.sitAndStand .sit-table .table-content tr td:last-child {
  width: 107px;
}
.sitAndStand .sit-table .table-content tr:first-child {
  width: 100%;
  border-bottom: 1px solid #000;
}
.sitAndStand .sit-table .result-content {
  margin-top: 20px;
  width: 90%;
}
.sitAndStand .sit-table .result-content table {
  width: 100%;
}
.sitAndStand .sit-table .result-content table tr {
  height: 49px;
}
.sitAndStand .sit-table .result-content table tr td {
  width: 33%;
  height: 21px;
  font-size: 20px;
  font-weight: normal;
  letter-spacing: 3px;
  line-height: 49px;
}
.sitAndStand .sit-table .result-content table tr:first-child td {
  border-top: 1px dashed #000;
}
.sitAndStand .sit-table .result-content table tr:last-child {
  /* border-bottom: 1px dashed #000; */
}
.sitAndStand .picture-content {
  display: flex;
  justify-content: space-around;
  width: 90%;
  align-items: center;
  margin: 0 auto;
  flex-wrap: wrap;
}
.sitAndStand .picture-content div {
  /* width: 33%; */
  flex: 1;
  width: calc(100% / 3);
  min-width: calc(100% / 3);
  max-width: calc(100% / 3);
  height: 400px;
}
.sitAndStand .sitParameters table {
  width: 90%;
  margin: 0 auto;
  margin-top: 40px;
}
.sitAndStand .sitParameters table th {
  height: 21px;
  font-size: 20px;
  font-weight: normal;
  line-height: 49px;
  letter-spacing: 3px;
  color: #000;
  font-size: 24px;
  border-bottom: 1px solid #000;
}
.sitAndStand .sitParameters table tr {
  width: 100%;
  line-height: 40px;
}
.sitAndStand .sitParameters table td {
  text-align: center;
  width: 33%;
  height: 21px;
  font-size: 20px;
  font-weight: normal;
  letter-spacing: 3px;
  line-height: 49px;
}
.game-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  width: 90%;
  margin-bottom: 40px;
}
.game-content .content-title {
  display: flex;
}
.game-content .content-title div {
  width: 85%;
  border-bottom: 3px solid #2f2e41;
}
.game-content .content-title span {
  width: 15%;
  font-size: 26px;
  line-height: 26px;
  color: #000;
  padding: 8px 0;
  text-align: center;
  background-color: #ddd;
  border: 1px solid #2f2e41;
}
.game-content .table-game {
  margin-top: 10px;
  margin: 0 auto;
  width: 90%;
}
.game-content .table-game table {
  width: 100%;
}
.game-content .table-game table tr {
  height: 49px;
}
.game-content .table-game table tr td {
  line-height: 49px;
  font-size: 20px;
  width: 33%;
  font-weight: normal;
  letter-spacing: 3px;
}
.game-content .game-echarts {
  display: flex;
  justify-content: center;
  /* margin: 0 auto; */
  align-items: center;
  /* height: 400px;
  width: 100%; */
}
.game-content .game{
  width: 90%;
  height: 400px;
}
.blance-content {
    margin: 0 auto;
    width: 90%;
    /* margin-top: 50px; */
    margin-bottom: 40px;
}
.blance-content .content-title {
    display: flex;
}
.blance-content .content-title div {
    width: 80%;
    border-bottom: 3px solid #2f2e41;
}
.blance-content .content-title span {
    width: 20%;
    font-size: 26px;
    line-height: 26px;
    color: #000;
    padding: 8px 0;
    text-align: center;
    background-color: #ddd;
    border: 1px solid #2f2e41;
}
.blance-content .blance-info {
    display: flex;
    width: 90%;
    margin: 0 auto;
    align-items: center;
    justify-content: space-around;
    margin-top: 10px;
    font-size: 20px;
    font-weight: normal;
    color: #000;
}
.blance-content .blance-info table {
    width: 100%;
}
.blance-content .blance-info table tr td {
    height: 21px;
    line-height: 49px;
    letter-spacing: 3px;
}
</style>